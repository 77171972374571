import is_panel from "./IsPanel"
import handles_uploads from "@/nibnut/mixins/HandlesUploads"
import handles_documents from "@/custom/mixins/HandlesDocuments"

const is_attachments_panel = {
    mixins: [is_panel, handles_uploads, handles_documents],
    mounted () {
        return this.reset_held_data()
    },
    watch: {
        "listing.id": "reset_held_data",
        wrapup: "maybe_upload_files"
    },
    methods: {
        attachments_except_types (types) {
            return this.owner_attachments_except_types("App\\Listing", this.listing.id, types)
        },
        attachments_of_types (types) {
            return this.owner_attachments_of_types("App\\Listing", this.listing.id, types)
        },
        reset_held_data () {
            this.held_attachments = {}
        },
        hold_attachment (type, url, name, id, file) {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "attachment" }
            ).then(record => {
                record.id = id
                record.owner_type = "App\\Listing"
                record.owner_id = this.record.id
                record.type = type
                record.name = (file.name || "").replace(/\.[^.]+$/, "")
                record.url = url
                record.sort_order = Object.keys(this.held_attachments).length + 1
                this.$set(this.held_attachments, name, record)
                this.$set(this.held, name, file)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        upload_attachment_data (type, attachment = null) {
            const data = {
                entity: "listing",
                id: this.listing.id,
                data: {
                    type
                }
            }
            if(attachment) data.data.attachment_id = attachment.uuid
            return data
        },
        upload_attached (name, record) {
            if(this.batch_uploading) {
                this.$delete(this.batch_uploading, name)
                this.upload_held_files()
            }
        },
        upload_file_post_name (name) {
            return name
        },
        upload_held_files () {
            if(!!this.listing && !!this.listing.id) {
                if(!this.batch_uploading) this.batch_uploading = { ...this.held }
                const names = Object.keys(this.batch_uploading)
                if(names.length) this.upload_file(names[0], this.held[names[0]], this.upload_file_post_name(names[0]))
                else {
                    this.batch_uploading = false
                    if(this.wrapup) this.wrapup()
                }
            }
        },
        maybe_upload_files () {
            if(this.wrapup) this.upload_held_files()
        }
    },
    computed: {
        holding_mode () {
            return !this.listing || !this.listing.id
        },
        attachments () {
            if(this.holding_mode) return Object.values(this.held_attachments)
            return this.entity_records("attachment")
        }
    },
    data () {
        return {
            held_attachments: {},
            batch_uploading: false
        }
    }
}

export default is_attachments_panel
