<template>
    <page
        id="listing-editor"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        :class="{ 'view': !is_my_record }"
        @statused="statused_and_set_ui"
    >
        <template v-slot:page-title>
            <div>
                <default-button
                    v-if="edited_record && is_at_least_realtor_for_listing(edited_record)"
                    id="page-notes"
                    :color="edited_record.notes_count ? 'primary' : 'regular'"
                    :title="translate('Notes')"
                    class="btn-action s-circle btn-notes"
                    @click.prevent="viewing_notes = !viewing_notes"
                >
                    <open-icon v-if="edited_record.notes_count" glyph="comment" />
                    <open-icon v-else glyph="comment-alt" />
                </default-button>
                <h3>{{ title }}</h3>
            </div>
        </template>
        <template v-slot:page-actions>
            <div v-if="!!edited_record && is_my_record" class="columns mls-id-container">
                <div class="column col-6 col-xl-12 col-lg-6 col-sm-12 d-flex">
                    <label class="form-label h3 flex-static mr-1">{{ translate("Listed on") }}</label>
                    <form-date-input
                        id="on_market_on"
                        name="on_market_on"
                        v-model="edited_record.on_market_on"
                        position="bottom"
                        :required="false"
                    />
                </div>
                <div class="column col-6 col-xl-12 col-lg-6 col-sm-12 d-flex">
                    <label class="form-label h3 flex-static mr-1">MLS&reg; #</label>
                    <form-mls-number-input
                        id="mls_id"
                        name="mls_id"
                        v-model="edited_record.mls_id"
                        :listing-id="edited_record.id || 0"
                        :relation-ids="relation_ids"
                        :required="false"
                    />
                </div>
            </div>
            <h3 v-else-if="!is_new_record && !!edited_record && !!edited_record.mls_id">MLS&reg; #{{ edited_record.mls_id }}</h3>
        </template>
        <template v-slot:page-title-addendum>
            <div
                v-if="!is_cma && is_at_least_realtor && !!edited_record && !!edited_record.message_count"
                :class="{ active: !!tooling }"
                class="d-flex d-flex-columns tools-drawer"
            >
                <div class="flex-static text-right tools-controls">
                    <div class="btn-group">
                        <default-button
                            v-if="is_at_least_realtor && !!edited_record.message_count"
                            tabindex="0"
                            size="sm"
                            :active="tooling === 'messages'"
                            @click.prevent="tooling = 'messages'"
                        >
                            <img src="@/assets/img/touchbase-logo.png" class="mr-1" />
                            <span class="hide-md">{{ translate("{n} Message(s)", { n: edited_record.message_count }) }}</span>
                            <span class="show-md">{{ edited_record.message_count | nibnut.number("0,0") }}</span>
                        </default-button>
                        <default-button
                            v-if="!!tooling"
                            tabindex="0"
                            size="sm"
                            @click.prevent="tooling = null"
                        >
                            <open-icon glyph="times" />
                        </default-button>
                    </div>
                </div>
                <div v-if="!!edited_record" class="tools-container">
                    <keep-alive>
                        <inbox
                            v-if="(tooling === 'messages') && is_at_least_realtor"
                            :listing-id="edited_record.id"
                        >
                            <span class="d-none">&nbsp;</span>
                        </inbox>
                    </keep-alive>
                </div>
            </div>
        </template>
        <listing-seller
            v-if="!!edited_record"
            :listing="edited_record"
            @input="save"
        />

        <master-detail
            v-if="is_my_record"
            :current-view.sync="current_view"
            :master-title="master_title"
            :detail-title="detail_title"
        >
            <template v-slot:left-view>
                <div class="d-flex px-2">
                    <label class="form-label text-bold flex-static">
                        {{ (edited_record.sale_price || edited_record.price) / 100 | nibnut.currency("0,0") }}
                    </label>
                    <label
                        v-if="!!edited_record.quick_statistics && !!edited_record.quick_statistics.price_changes && !!edited_record.quick_statistics.price_changes.length"
                        :data-tooltip="price_changes_list"
                        :class="{ 'tooltip': (edited_record.quick_statistics.price_changes.length > 2) }"
                        class="form-label text-gray flex-static"
                    >
                        &nbsp;&bull;&nbsp;{{ edited_record.quick_statistics.price_changes[1] }}
                    </label>
                </div>
            </template>
            <template
                v-slot:master
                v-if="!!edited_record && !is_new_record && is_at_least_seller_for_listing(edited_record)"
            >
                <statistics
                    id="section-statistics"
                    :listing="edited_record"
                    class="mt-4"
                />
                <showings-list
                    id="section-showings"
                    :listing="edited_record"
                    class="nibnut-scroll-target mt-4"
                />
                <activity
                    v-if="!is_cma"
                    id="section-activity"
                    :record="edited_record"
                    class="nibnut-scroll-target mt-4"
                />
                <listing-comparables
                    id="section-comparables"
                    :listing="edited_record"
                    class="nibnut-scroll-target mt-4"
                />
            </template>
            <template v-slot:detail>
                <div v-if="edited_record">
                    <div class="card">
                        <component
                            v-for="section in sections"
                            :key="section.id"
                            :is="section.component"
                            :id="section.id"
                            :listing="edited_record"
                            :title="section.title"
                            :held-records="section_held_records(section.id)"
                            :relation-ids="relation_ids"
                            :wrapup="wrapup_creation"
                            :all-errors="errors"
                            class="nibnut-scroll-target"
                            @shown="section.shown = $event"
                            @input="save"
                            @add="add_relationship_record"
                            @delete="delete_relationship_record"
                        ></component>
                    </div>
                    <div v-if="!is_new_record && !is_mls_record && is_my_record" class="text-center">
                        <default-button
                            flavor="link"
                            color="error"
                            :waiting="deleting_record"
                            :disabled="creating || deleting_record"
                            @click.prevent="confirm_record_delete"
                        >
                            <open-icon glyph="minus-circle" />
                            {{ translate("Delete Listing") }}
                        </default-button>
                    </div>
                </div>
            </template>
        </master-detail>
        <listing-view
            v-else-if="edited_record"
            :listing="edited_record"
            :sections="sections"
        />
        <div
            v-if="is_my_record"
            id="page-toc"
            :class="{ active: toc_opened }"
            class="popover popover-top hover-disabled"
        >
            <default-button
                color="primary"
                :title="translate('Quick page navigation...')"
                class="btn-action s-circle"
                @click.prevent="toc_opened = !toc_opened"
            >
                <open-icon glyph="ellipsis-v" />
            </default-button>
            <div class="popover-container">
                <ul class="menu">
                    <li
                        v-for="section in toc_sections"
                        :key="section.id"
                        class="menu-item"
                    >
                        <base-link
                            :href="`#${section.id}`"
                            @click.prevent.stop="scroll_to_hash(`#${section.id}`)"
                        >
                            {{ section.title }}
                        </base-link>
                    </li>
                </ul>
            </div>
        </div>

        <template v-slot:footer>
            <div
                v-if="is_new_record"
                ref="footer"
                :style="{ 'margin-top': `${footer_margin}px` }"
                class="page-content-footer"
            >
                <default-button
                    :disabled="creating"
                    class="mx-2"
                    @click.prevent="navigate_back"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    :disabled="creating"
                    :waiting="creating"
                    class="mx-2"
                    @click.prevent="create_record"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </template>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />

        <note-pad
            v-if="edited_record && edited_record.id && is_at_least_realtor_for_listing(edited_record)"
            :show.sync="viewing_notes"
            topic-entity="App\Listing"
            :topic-entity-id="edited_record.id"
            @loaded="edited_record.notes_count = $event"
        >
            <template v-slot:subtitle>
                <span class="text-gray">{{ title }}</span>
            </template>
        </note-pad>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, string_utilities } from "@/nibnut/mixins"
import is_editor from "./IsEditor"

import { FormInput, FormDateInput, DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"
import { ListingComparables, FormMlsNumberInput, MasterDetail, ShowingsList, ListingCard, Inbox, ListingView, NotePad } from "@/custom/components"

import ListingSeller from "./ListingSeller"
import Statistics from "./Statistics"

import ListingBuilding from "./ListingBuilding"
import ListingMedia from "./ListingMedia"
import ListingProperty from "./ListingProperty"
import ListingPublication from "./ListingPublication"
import ListingCommunity from "./ListingCommunity"
import ListingAmenities from "./ListingAmenities"
import ListingInterior from "./ListingInterior"
import ListingExterior from "./ListingExterior"
import ListingDocuments from "./ListingDocuments"
import ListingLinks from "./ListingLinks"
import CmaInformation from "./CmaInformation"

import QuickStats from "./QuickStats"
import Activity from "./Activity"

let new_id = -1

export default {
    mixins: [is_record_editor_page, string_utilities, is_editor],
    components: {
        FormInput,
        FormDateInput,
        DefaultButton,
        BaseLink,
        OpenIcon,
        ListingComparables,
        FormMlsNumberInput,
        MasterDetail,
        ShowingsList,
        ListingCard,
        Inbox,
        ListingView,
        NotePad,
        ListingSeller,
        Statistics,
        QuickStats,
        Activity
    },
    created () {
        this.load_default_vocabularies()
    },
    mounted () {
        this.reset_ui()
    },
    watch: {
        $route: "reset_ui",
        profile_id: "load_default_vocabularies",
        "edited_record.id": "reset_ui"
    },
    methods: {
        reset_ui () {
            if(!this.edited_record || !this.edited_record.id) return
            this.held = {}
            this.held_images = {}
            let node = document.querySelector("header.navbar")
            if(node) {
                this.header_margin = node.clientHeight
                node = document.querySelector(".page-content-header")
                if(node) node.style.top = `${this.header_margin}px`
            }
            this.current_view = this.is_at_least_seller_for_listing(this.edited_record) ? "master" : "detail"
            if(!this.is_my_record && window.gtag) window.gtag("set", "screen_name", this.edited_record.uuid)
        },
        statused_and_set_ui (status) {
            this.statused(status)
            if(status === 200) setTimeout(this.reset_ui, 250)
        },
        load_default_vocabularies () {
            if(this.profile_id) {
                const vocabularies = [
                    this.constants("vocabularies", "VOCABULARY_ARCHITECTURAL_STYLES").id,
                    this.constants("vocabularies", "VOCABULARY_INTERIOR_FEATURES").id,
                    this.constants("vocabularies", "VOCABULARY_EXTERIOR_FEATURES").id,
                    this.constants("vocabularies", "VOCABULARY_HEATING_SOURCES").id,
                    this.constants("vocabularies", "VOCABULARY_FIREPLACE_TYPES").id,
                    this.constants("vocabularies", "VOCABULARY_COOLING_SOURCES").id,
                    this.constants("vocabularies", "VOCABULARY_LIFESTYLES").id,
                    this.constants("vocabularies", "VOCABULARY_ROOM_TYPES").id,
                    this.constants("vocabularies", "VOCABULARY_LISTING_STATUS").id,
                    this.constants("vocabularies", "VOCABULARY_HOUSE_LEVELS").id,
                    this.constants("vocabularies", "VOCABULARY_LISTING_ACTIONS").id
                ]
                // if(this.is_new_record || this.is_at_least_seller_for_listing(edited_record)) { // no comparables loaded
                vocabularies.push(this.constants("vocabularies", "VOCABULARY_PROPERTY_TYPES").id)
                vocabularies.push(this.constants("vocabularies", "VOCABULARY_LOT_FEATURES").id)
                vocabularies.push(this.constants("vocabularies", "VOCABULARY_VIEW_TYPES").id)
                vocabularies.push(this.constants("vocabularies", "VOCABULARY_WATERFRONT_FEATURES").id)
                vocabularies.push(this.constants("vocabularies", "VOCABULARY_NEIGHBORHOODS").id)
                // }
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "term",
                        query: {
                            vocabularies
                        }
                    }
                )
            }
        },
        website_by_id (id) {
            return this.entity_record("website", id)
        },
        navigate_to (section_id) {
            this.scroll_to(document.getElementById(section_id))
        },
        create_record () {
            this.waiting = true
            this.creating = true
            const data = {
                ...this.edited_record,
                _rooms: this.held_records.room,
                _listing_websites: this.held_records.listing_website
            }
            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data
            }).then(record => {
                this.held_records = {
                    room: {},
                    listing_website: {}
                }
                this.edited_record.id = record.id
                this.edited_record.slug = record.slug
                this.wrapup_creation = () => {
                    this.creating = false
                    this.waiting = false
                    this.$router.replace({ name: "listing.edit", params: { id: record.slug } })
                }
            }).catch(error => {
                this.receive_error(error)
                this.creating = false
                this.waiting = false
            })
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.go(-1)
        },
        navigate_back () {
            this.$router.replace({ name: "listing.mine" })
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) return this.save_field(this.edited_record[field], field)
            }
            return Promise.resolve()
        },
        toggle_section (section, shown) {
            this.$set(section, "shown", shown)
        },
        section_held_records (section) {
            if(section === "publication-settings") return this.held_records.listing_website
            if(section === "building-information") return this.held_records.room
            return null
        },
        add_relationship_record (entity, record, callback = null) {
            if(this.is_overridable) {
                record.listing_id = this.edited_record.id
                if(this.is_new_record) {
                    record.id = new_id--
                    if(!this.held_records[entity]) this.held_records[entity] = {}
                    this.$set(this.held_records[entity], record.id, record)
                } else {
                    this.$store.dispatch("CREATE_RECORD", {
                        entity: entity,
                        data: record
                    }).then(() => {
                        if(callback) callback()
                    }).catch(this.receive_error)
                }
            }
        },
        delete_relationship_record (entity, record) {
            if(this.is_overridable && (record.id < 0)) {
                this.$delete(this.held_records[entity], record.id)
                if(!Object.keys(this.held_records[entity]).length) this.held_records[entity] = null
            }
        },
        scroll_to_hash (hash) {
            const id = hash.replace(/#/, "")
            const node = document.getElementById(id)
            if(node) {
                this.scroll_margin_top = 140
                this.$nextTick(() => {
                    node.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" })
                })
            }
            this.toc_opened = false
        }
    },
    computed: {
        can_reload () {
            return !!this.profile_id
        },
        reload_query () {
            return {
                relation_ids: this.relation_ids,
                fields: [
                    "ns::user;fieldset::listing-editor",
                    "ns::attachment;fieldset::listing-editor",
                    "ns::room;fieldset::listing-editor",
                    "ns::listing_website;fieldset::listing-editor",
                    "ns::listing_action;fieldset::listing-editor",
                    "ns::listing_showing;fieldset::listing-editor",
                    "ns::listing_feedback;fieldset::listing-editor",
                    "ns::listing_email;fieldset::listing-editor",
                    "ns::user;fieldset::listing-editor"
                ]
            }
        },
        record () {
            return this.edited_record
        },
        title () {
            if(!!this.edited_record && !!this.edited_record.line1) return this.address_one_line(this.edited_record)
            return this.translate("Listing Editor")
        },
        master_title () {
            if(this.is_view || (this.current_view === "master")) return ""
            return this.translate("Control Panel")
        },
        detail_title () {
            if(this.is_view || (this.current_view === "detail")) return ""
            return this.translate("Property")
        },
        sections () {
            const sections = []
            if(this.is_cma) sections.push({ id: "cma", title: this.translate("CMA"), component: CmaInformation, shown: true })
            else {
                const listing_is_land_only = this.listing_is_land_only(this.edited_record)
                if(this.is_my_record) sections.push({ id: "publication-settings", title: this.translate("Publication Settings"), component: ListingPublication, shown: true })
                sections.push({ id: "property-information", title: this.translate("Property Information"), component: ListingProperty, shown: true })
                sections.push({ id: "community-information", title: this.translate("Community Information"), component: ListingCommunity, shown: true })
                if(!listing_is_land_only) {
                    sections.push({ id: "amenities", title: this.translate("Amenities"), component: ListingAmenities, shown: true })
                    sections.push({ id: "interior-features", title: this.translate("Interior Features"), component: ListingInterior, shown: true })
                }
                sections.push({ id: "exterior-information", title: this.translate("Exterior Features"), component: ListingExterior, shown: true })
                if(!listing_is_land_only) {
                    sections.push({ id: "building-information", title: this.translate("Rooms"), component: ListingBuilding, shown: true })
                }
                sections.push({ id: "media", title: this.translate("Media"), component: ListingMedia })
                if(this.is_my_record) {
                    sections.push({ id: "documents", title: this.translate("Documents"), component: ListingDocuments, shown: true })
                    sections.push({ id: "links", title: this.translate("Links"), component: ListingLinks, shown: true })
                }
            }
            return sections
        },
        toc_sections () {
            if(!this.is_view && (this.current_view === "master")) {
                return [
                    { id: "section-statistics", title: this.translate("Statistics") },
                    { id: "section-showings", title: this.translate("Showings") },
                    { id: "section-activity", title: this.translate("Recent Activity") },
                    { id: "section-comparables", title: this.translate("Comparables") }
                ]
            }
            return this.sections.filter(section => {
                return !!section.shown
            })
        },
        is_new_record () {
            return !!this.edited_record && !this.edited_record.id
        },
        is_cma () {
            return !!this.edited_record && !this.edited_record.on_market_on
        },
        is_view () {
            if(!this.edited_record) return false
            if(this.is_at_least_administrator) return false
            if(this.is_realtor && this.is_my_record) return false
            return true
        },
        todays_showing_count () {
            if(!this.edited_record || !this.edited_record.id || this.is_new_record) return 0
            return this.entity_records("listing_showing").filter(listing_showing => {
                return (listing_showing.listing_id === this.edited_record.id) && !!listing_showing.starts_at && this.$dayjs(listing_showing.starts_at).isToday()
            }).length
        },
        price_changes_list () {
            if(!this.edited_record || !this.edited_record.quick_statistics || !this.edited_record.quick_statistics.price_changes) return null
            return this.edited_record.quick_statistics.price_changes.join("\n")
        }
    },
    data () {
        return {
            entity: "listing",
            entityName: "Listing:::Listings",
            relation_ids: ["attachment", "room", "listing_website", "listing_action", "listing_showing", "listing_feedback", "user", "website"],
            held_records: {
                room: null,
                listing_website: null
            },

            current_view: "master",

            tooling: null,
            creating: false,
            wrapup_creation: null,

            header_margin: 68,
            footer_margin: 48,
            toc_opened: false,
            scroll_margin_top: 140,

            viewing_notes: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.nibnut > .nibnut-app.listing-edit {
    & > .non-navigation > main,
    .page {
        padding: 0;
    }
}
#listing-editor {
    padding: $unit-6;
    .nibnut-scroll-target {
        scroll-margin-top: 140px;
    }
    .column {
        position: relative;
    }
    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .tools-drawer {
        position: absolute;
        right: 0;
        top: 100%;
        pointer-events: none;
        z-index: $zindex-3;

        .btn img {
            max-height: 0.8em;
        }
        & > .tools-controls {
            padding-right: $layout-spacing-lg * 2;
            pointer-events: none;
            .btn-group {
                pointer-events: all;
                white-space: nowrap;
            }
        }
        .tools-container {
            position: relative;
            flex: 1 1 1px;
            height: 0;
            overflow: hidden;
            background-color: $light-color;
            border: $border-width solid $border-color;
            border-bottom: 0;
            padding: 0;
            pointer-events: all;
            transition: all .2s ease;
        }
        &.active {
            width: 100%;
            max-height: calc(100vh - 130px);
            background-color: $body-bg;
            .tools-container {
                flex: 1 1 auto;
                height: auto;
                overflow: auto;
            }
        }
    }
    .page-content-header {
        position: sticky;
        top: 0;
        margin-left: 0 - $unit-6;
        margin-right: 0 - $unit-6;
        padding: $control-padding-x-lg $unit-6;
        background-color: $body-bg;
        align-items: flex-start;
        z-index: $zindex-3 + 10;

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0;
        }
        h5 {
            font-weight: normal;
        }
        .summary-stats {
            display: flex;
            justify-content: flex-end;
            .summary-stat {
                flex: 0 0 100px;
                text-align: center;
            }
        }
        .mls-id-container > div.d-flex {
            justify-content: flex-end;
            @media (min-width: ($size-lg + 1)) and (max-width: $size-xl) {
                justify-content: flex-end;

                & + div.d-flex {
                    margin-top: $unit-2;
                }
            }
            @media (max-width: $size-lg) {
                justify-content: flex-start;
            }
        }
    }
    section + section {
        margin-top: $unit-8;
    }
    .card:not(.comparables):not(.chance-of-selling) {
        .card-body {
            .columns {
                @media (min-width: $size-lg) {
                    & > .column:not(.col-2):not(.col-1) {
                        min-width: 25%;
                    }
                }
                &.by-1 {
                    & > .column {
                        min-width: 100%;
                    }
                }
                &.by-2 {
                    & > .column {
                        min-width: 50%;
                    }
                }
                &.by-3 {
                    & > .column {
                        min-width: 33.3333%;
                    }
                }
            }
        }
    }
    .page-content-footer {
        display: flex;
        justify-content: center;
    }

    #page-notes {
        position: absolute;
        left: -2.2rem;
    }
    & > .columns {
        & > .column {
            min-height: 100%;

            & > div, .card {
                height: 100%;
            }
        }
    }
    #page-toc {
        position: fixed;
        bottom: $layout-spacing;
        right: $layout-spacing;
        z-index: $zindex-1;

        & > .popover-container {
            left: auto;
            right: 0;
            max-height: 300px;
            overflow-y: auto;
            transform: translate(0, -50%) scale(0);
        }

        &.active {
            & > .popover-container {
                transform: translate(0, -100%);

                ul.menu {
                    font-size: 0.65rem;

                    .menu-item + .menu-item {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
main .view {
    #listing-editor {
        padding: 0;
        .page-content-header {
            display: none;
        }
    }
}
</style>
