<template>
    <listing-panel
        :id="id"
        :title="title"
    >
        <template v-slot:title>
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <div class="card-title h5 flex-variable">
                        {{ title }}<span v-if="is_developer"> (Website #{{ current_listing_website ? current_listing_website.id : "?" }})</span>
                    </div>
                    <form-toggle-input
                        id="is_demo_listing"
                        name="is_demo_listing"
                        type="checkbox"
                        :value="listing.is_demo_listing"
                        :required="false"
                        class="flex-static mb-0"
                        @input="save_property"
                    >
                        {{ translate("Demo Listing") }}
                    </form-toggle-input>
                    <form-toggle-input
                        id="publish_on"
                        name="publish_on"
                        type="checkbox"
                        :value="!current_listing_website.publish_on"
                        :required="false"
                        class="flex-static"
                        @input="toggle_publication(current_website)"
                    >
                        {{ translate("Hide on WordPress") }}
                    </form-toggle-input>
                </div>
            </div>
        </template>
        <div class="listing-publication-panel">
            <div class="columns">
                <form-input
                    id="title"
                    name="title"
                    v-model="current_listing_website.title"
                    :placeholder="default_title"
                    :required="false"
                    class="column col-10 col-md-9 col-sm-8"
                    @input="save"
                >
                    <template v-slot:label>{{ translate("Page Title") }}</template>
                </form-input>
                <form-input
                    id="sort_order"
                    name="sort_order"
                    :value="$dayjs.unix(current_listing_website.sort_order).format('YYYY-MM-DD HH:mm')"
                    :required="false"
                    class="column col-2 col-md-3 col-sm-4"
                    @input="save_sort_order"
                >
                    <template v-slot:label>{{ translate("Priority") }}</template>
                </form-input>
                <form-textbox
                    id="description"
                    name="description"
                    v-model="current_listing_website.description"
                    size="md"
                    :required="false"
                    class="column col-12"
                    @input="save"
                >
                    <template v-slot:label>{{ translate("Page Description") }}</template>
                </form-textbox>
                <form-token-input
                    id="keywords"
                    name="keywords"
                    :value="current_listing_website.keywords"
                    :required="false"
                    :separators="[',','Enter']"
                    class="column col-12"
                    @input="add_keyword"
                    @delete="remove_keyword"
                >
                    <template v-slot:label>{{ translate("Keywords") }}</template>
                </form-token-input>
                <div class="column col-12 mt-2">
                    <label class="form-label">{{ translate("Lifestyles") }}</label>
                    <form-toggle-input
                        v-for="lifestyle in lifestyles"
                        :key="lifestyle.id"
                        :id="`lifestyle-${lifestyle.id}`"
                        name="lifestyle_ids"
                        type="checkbox"
                        :value="tag_is_active(current_listing_website.term_ids, lifestyle)"
                        :required="false"
                        class="website-tile mb-0 mr-4"
                        @input="toggle_lifestyle(lifestyle)"
                    >
                        {{ lifestyle.name }}
                    </form-toggle-input>
                </div>
            </div>
            <!--
            <tabs
                :tabs="websites"
                :current-tab-id="current_website.uuid"
                id-field="uuid"
                label-field="name"
                @click="change_current_website_id"
            >
                <template v-slot:tab="{ tab }">
                    <div
                        :class="{ published: published_on_website(tab) }"
                        class="columns align-items-center"
                    >
                        <div v-if="tab.image_url" class="column flex-static">
                            <img
                                :src="tab.image_url"
                                :alt="tab.name"
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <div class="column flex-grow text-left">
                            {{ tab.name }}
                            <div v-if="listing_website_for_website(tab.id)" class="text-small text-gray">
                                <span v-if="published_on_website(tab)">{{ translate("Publication: ") }} {{ listing_website_for_website(tab.id).publish_on | nibnut.date }}</span>
                                <span v-else>({{ translate("Not published") }})</span>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="my-3 publication-controls">
                    <default-button
                        :title="published_on_website(current_website) ? translate('Unpublish') : translate('Publish')"
                        :disabled="publishing(current_website)"
                        @click.prevent.stop="toggle_publication(current_website)"
                    >
                        <open-icon
                            :glyph="published_on_website(current_website) ? 'cloud-download-alt' : 'cloud-upload-alt'"
                            size="lg"
                        />
                        {{ published_on_website(current_website) ? translate('Unpublish') : translate('Publish') }}
                    </default-button>
                    <base-link
                        v-if="published_on_website(current_website)"
                        :href="listing_website_for_website(current_website.id).url || 'https://nibnut.com'"
                        :title="translate('View on {website}', { website: current_website.name })"
                        target="_blank"
                        class="btn"
                    >
                        <open-icon
                            glyph="external-link-alt"
                            size="lg"
                        />
                        {{ translate('View on {website}', { website: current_website.name }) }}
                    </base-link>
                </div>
                <div class="columns">
                    <div class="column col-12">
                        <form-input
                            id="title"
                            name="title"
                            v-model="current_listing_website.title"
                            :placeholder="default_title"
                            :required="false"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Page Title") }}</template>
                        </form-input>
                        <form-textbox
                            id="description"
                            name="description"
                            v-model="current_listing_website.description"
                            size="md"
                            :required="false"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Page Description") }}</template>
                        </form-textbox>
                    </div>
                    <div class="column col-12 mt-2">
                        <label class="form-label">{{ translate("Lifestyles") }}</label>
                        <form-toggle-input
                            v-for="lifestyle in lifestyles"
                            :key="lifestyle.id"
                            :id="`lifestyle-${lifestyle.id}`"
                            name="lifestyle_ids"
                            type="checkbox"
                            :value="tag_is_active(current_listing_website.term_ids, lifestyle)"
                            :required="false"
                            class="website-tile mb-0 mr-4"
                            @input="toggle_lifestyle(lifestyle)"
                        >
                            {{ lifestyle.name }}
                        </form-toggle-input>
                    </div>
                </div>
            </tabs>
            //-->
        </div>
    </listing-panel>
</template>

<script>
import string_utilities from "@/nibnut/mixins/StringUtilities"
import is_panel from "./IsPanel"

// import Tabs from "@/nibnut/components/Containers/Tabs"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import FormTokenInput from "@/nibnut/components/Inputs/FormTokenInput"
// import BaseLink from "@/nibnut/components/Links/BaseLink"
// import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
// import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ListingPublication",
    mixins: [is_panel, string_utilities],
    components: {
        FormInput,
        FormToggleInput,
        FormTextbox,
        FormTokenInput
    },
    mounted () {
        this.reload_shell()
    },
    watch: {
        profile_id: "reload_shell",
        shell: "reset_current_website_id",
        $route: "reset_current_website_id",
        websites: "reset_current_website_id"
    },
    methods: {
        reload_shell () {
            if(this.is_overridable) {
                this.$store.dispatch("FETCH_RECORD_SHELL", { entity: this.entity }).then(record => {
                    this.shell = record
                    this.shell.listing_id = this.listing.id
                    this.shell.website_id = this.current_website.id
                })
            }
        },
        grab_record_id () {
            this.record_id = this.current_listing_website.id
        },
        reset_current_website_id () {
            const websites = this.websites
            if(websites.length) {
                if(!!this.listing && !this.listing.id && !!this.shell) {
                    websites.forEach(website => {
                        const listing_website = this.listing_website_for_website(website.id)
                        if(!listing_website) {
                            this.$emit("add", this.entity, {
                                ...this.shell,
                                website_id: website.id
                            })
                        }
                    })
                }
                if(!this.current_website_id || !websites.find(website => website.id === this.current_website_id)) this.current_website_id = websites[0].id
                this.record_id = this.current_website_id
            }
        },
        change_current_website_id (website_uuid) {
            this.current_website_id = this.website_from_uuid(website_uuid).id || 0
        },
        website_from_uuid (uuid) {
            return this.websites.find(website => website.uuid === uuid) || {}
        },
        listing_website_for_website (website_id) {
            return this.listing_websites.find(listing_website => listing_website.website_id === website_id)
        },
        published_on_website (website) {
            if(website) {
                const listing_website = this.listing_website_for_website(website.id)
                return !!listing_website && !!listing_website.publish_on && this.$dayjs(listing_website.publish_on).isSameOrBefore()
            }
            return false
        },
        save (value, field) {
            const listing_website = this.current_listing_website
            this.grab_record_id()
            if(listing_website && !!listing_website.id) {
                if(listing_website[field] !== value) listing_website[field] = value
                this.save_field(listing_website[field], field)
            } else {
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data: {
                        listing_id: this.listing.id,
                        website_id: this.current_listing_website.website_id,
                        [field]: value
                    }
                }).then(() => {
                    // nothing to do?
                }).catch(this.receive_error)
            }
        },
        add_keyword (keyword) {
            keyword = keyword.toLowerCase()
            const listing_website = this.current_listing_website
            const keywords = listing_website.keywords ? [...listing_website.keywords] : []
            const index = keywords.indexOf(keyword)
            if(index < 0) keywords.push(keyword)
            this.save(keywords, "keywords")
        },
        remove_keyword (keyword) {
            const listing_website = this.current_listing_website
            const keywords = listing_website.keywords ? [...listing_website.keywords] : []
            const index = keywords.indexOf(keyword.toLowerCase())
            if(index >= 0) keywords.splice(index, 1)
            this.save(keywords, "keywords")
        },
        save_sort_order (value, field) {
            this.save(this.$dayjs(value).unix(), field)
        },
        save_property (value, field) {
            this.$emit("input", value, field)
        },
        publishing (website) {
            return !!this.publishing_websites[website.uuid]
        },
        toggle_lifestyle (lifestyle) {
            const listing_website = this.current_listing_website
            this.toggle_tag(listing_website.term_ids, lifestyle)
            this.save(listing_website.term_ids, "term_ids")
        },
        toggle_publication (listing_website) {
            if(listing_website) {
                if(listing_website.publish_on) listing_website.publish_on = null
                else listing_website.publish_on = this.$dayjs().format("YYYY-MM-DD")
            }
            if(listing_website.id > 0) {
                this.save_field_for_record_id(this.entity, listing_website.id, listing_website.publish_on, "publish_on").then(record => {
                    if(!this.has_error()) {
                        if(record.publish_on) this.$success(this.translate("The listing will be hidden shortly."))
                        else this.$success(this.translate("The listing will be published shortly."))
                    }
                })
            }
        }
        /*
        toggle_publication (website) {
            const listing_website = this.listing_website_for_website(website.id)
            if(listing_website) {
                if(listing_website.publish_on) listing_website.publish_on = null
                else listing_website.publish_on = this.$dayjs().format("YYYY-MM-DD")
            }
            if(listing_website.id > 0) {
                this.save_field_for_record_id(this.entity, listing_website.id, listing_website.publish_on, "publish_on").then(record => {
                    if(!this.has_error()) {
                        if(record.publish_on) this.$success(this.translate("The listing will be published on '{website}' shortly.", { website: website.name }))
                        else this.$success(this.translate("The listing will be hidden on '{website}' shortly.", { website: website.name }))
                    }
                })
            }
        }
        */
    },
    computed: {
        default_title () {
            return this.string_concat(", ", this.address_block_line(1, this.listing), this.listing.city)
        },
        listing_websites () {
            if(!this.listing) return []
            if(!this.listing.id && !!this.heldRecords) return Object.values(this.heldRecords)
            return this.entity_records("listing_website").filter(listing_website => listing_website.listing_id === this.listing.id)
        },
        current_website () {
            if(!this.current_website_id) return {}
            return this.websites.find(website => website.id === this.current_website_id) || {}
        },
        current_listing_website () {
            const current_website = this.current_website
            const current_listing_website = this.listing_website_for_website(current_website.id) || {
                ...this.shell,
                website_id: current_website.id
            }
            return current_listing_website
        }
    },
    data () {
        return {
            entity: "listing_website",
            current_website_id: "",
            publishing_websites: {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listing-publication-panel {
    .tab-item,
    .dropdown {
        img {
            display: block;
            position: relative;
            height: 1.5rem;
            opacity: 0.2
        }
        .published {
            font-weight: bold;
            img {
                opacity: 1;
            }
        }
    }
    .publication-controls {
        a.btn:last-child {
            $margin-left: $unit-4;
        }
    }
    @media (max-width: $size-sm) {
        .btn + .btn {
            margin-top: $unit-4;
        }
        .publication-controls {
            a.btn:last-child {
                $margin-left: 0;
            }
        }
    }
}
</style>
