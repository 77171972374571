<template>
    <div v-if="!!listing">
        <address-input
            id="address"
            name="address"
            :value="address"
            :fixed-country="true"
            :editable="is_overridable"
            :required="true"
            :errors="allErrors"
            @input="save_address"
        />
    </div>
</template>

<script>
import is_panel from "./IsPanel"

import AddressInput from "@/nibnut/components/Inputs/AddressInput/AddressInput"

const fields = ["line1", "line2", "city", "state", "zip", "country"]

export default {
    name: "ListingAddress",
    mixins: [is_panel],
    components: {
        AddressInput
    },
    mounted () {
        this.reset_address()
    },
    watch: {
        "listing.line1": "reset_address",
        "listing.line2": "reset_address",
        "listing.city": "reset_address",
        "listing.state": "reset_address",
        "listing.zip": "reset_address",
        "listing.country": "reset_address"
    },
    methods: {
        reset_address () {
            const changed_field = !!fields.find(field => {
                return this.listing[field] !== this.address[field]
            })
            if(changed_field) {
                const address = {}
                fields.forEach(field => {
                    address[field] = this.standardized_field_value(this.listing, field, null)
                })
                this.address = address
            }
        },
        save_address () {
            let changed_field = false
            fields.forEach(field => {
                if(!changed_field) changed_field = (this.listing[field] !== this.address[field])
                this.listing[field] = this.address[field]
            })
            if(this.listing.id && changed_field) this.save_data_for_record_id("listing", this.listing.id, this.address)
            this.$emit("input", this.address, "address")
        }
    },
    data () {
        return {
            address: {
                line1: null,
                line2: null,
                city: null,
                state: null,
                country: null,
                zip: null
            }
        }
    }
}
</script>
