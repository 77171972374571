<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        :title="title"
    >
        <listing-attribute :shown="true">
            <template v-slot:label>{{ translate("Price") }}</template>
            <form-input
                id="price"
                name="price"
                :value="listing.price"
                type="stripe"
                glyph="dollar-sign"
                glyph-position="left"
                :min="1"
                :required="true"
                :editable="is_overridable && !is_mls_record"
                :error="has_error('price')"
                @input="save"
            >
                <template v-slot:empty-value>{{ (listing.price / 100) | nibnut.listing_price(listing.price_frequency) }}</template>
                <template v-if="!!listing.price_frequency" v-slot:right_addon>
                    <span class="input-group-addon">
                        {{ listing.price_frequency }}
                    </span>
                </template>
            </form-input>
        </listing-attribute>
        <listing-attribute :shown="true">
            <template v-slot:label>{{ translate("Address") }}</template>
            <listing-address
                id="record-address"
                :listing="listing"
                :all-errors="allErrors"
            />
        </listing-attribute>
        <listing-attribute :shown="true">
            <template v-slot:label>{{ translate("Area") }}</template>
            <form-multi-select
                id="neighborhood_id"
                name="neighborhood_id"
                :value="listing.neighborhood_id ? [listing.neighborhood_id] : []"
                :options="neighborhoods"
                display="chips"
                :required="true"
                :editable="is_overridable && !is_mls_record"
                :max-selection="1"
                :error="has_error('neighborhood_id')"
                class="text-left"
                @input="save_neighborhood"
            />
        </listing-attribute>
    </listing-panel>
</template>

<script>
import is_panel from "./IsPanel"

import { FormInput } from "@/nibnut/components"
import { FormMultiSelect } from "@/custom/components"
import ListingAddress from "./ListingAddress"
import ListingAttribute from "./ListingAttribute"

export default {
    name: "CmaInformation",
    mixins: [is_panel],
    components: {
        FormInput,
        FormMultiSelect,
        ListingAddress,
        ListingAttribute
    },
    methods: {
        save_neighborhood (value, field) {
            if(!!value && !!value.length) value = value[0]
            this.save(value || 0, field)
        }
    },
    computed: {
        neighborhoods () {
            return this.vocabulary("VOCABULARY_NEIGHBORHOODS")
        }
    },
    data () {
        return {
        }
    }
}
</script>
