<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        :title="title"
    >
        <div class="columns">
            <listing-attribute
                :shown="attribute_shown('parking_spots')"
                :vertical="true"
                class="column"
            >
                <template v-slot:label>{{ translate("Parking") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    <listing-attribute :shown="attribute_shown('garage_spots')" class="col-2 col-md-4 col-sm-12">
                        <template v-slot:label>{{ translate("Garage") }}</template>
                        <form-input
                            id="garage_spots"
                            name="garage_spots"
                            v-model="listing.garage_spots"
                            type="number"
                            :min="0"
                            :required="false"
                            :editable="is_overridable && !is_mls_record"
                            :error="has_error('garage_spots')"
                            @input="save"
                        />
                    </listing-attribute>
                    <listing-attribute :shown="attribute_shown('exterior_spots')" class="col-2 col-md-4 col-sm-12">
                        <template v-slot:label>{{ translate("Exterior") }}</template>
                        <form-input
                            id="exterior_spots"
                            name="exterior_spots"
                            v-model="listing.exterior_spots"
                            type="number"
                            :min="0"
                            :required="false"
                            :editable="is_overridable && !is_mls_record"
                            :error="has_error('exterior_spots')"
                            @input="save"
                        />
                    </listing-attribute>
                    <listing-attribute :shown="attribute_shown('covered_spots')" class="col-2 col-md-4 col-sm-12">
                        <template v-slot:label>{{ translate("Covered") }}</template>
                        <form-input
                            id="covered_spots"
                            name="covered_spots"
                            v-model="listing.covered_spots"
                            type="number"
                            :min="0"
                            :required="false"
                            :editable="is_overridable && !is_mls_record"
                            :error="has_error('covered_spots')"
                            @input="save"
                        />
                    </listing-attribute>
                    <listing-attribute class="col-2 col-md-4 col-sm-12">
                        <template v-slot:label>
                            <span v-if="!!attribute_shown('garage_spots') || !!attribute_shown('exterior_spots') || !!attribute_shown('covered_spots')">{{ translate("Total") }}</span>
                            <span v-else>&nbsp;</span>
                        </template>
                        <form-input
                            id="parking_spots"
                            name="parking_spots"
                            v-model="listing.parking_spots"
                            type="number"
                            :min="0"
                            :required="false"
                            :editable="is_overridable && !is_mls_record"
                            :error="(listing.parking_spots !== total_parking_spots) ? translate('Mismatch') : has_error('parking_spots')"
                            @input="save"
                        />
                    </listing-attribute>
                </label>
            </listing-attribute>
            <listing-attribute
                :shown="attribute_shown('computed_kitchen_count')"
                :vertical="true"
                class="column"
            >
                <template v-slot:label>{{ translate("Kitchen") }}</template>
                <base-link
                    v-if="is_overridable && !is_mls_record"
                    href="#building-information"
                    class="form-label disabled-field"
                    @click.prevent.stop="navigate_to"
                >
                    {{ listing.computed_kitchen_count | nibnut.number("0,0") }}
                </base-link>
                <label
                    v-else
                    class="form-label disabled-field"
                >
                    {{ listing.computed_kitchen_count | nibnut.number("0,0") }}
                </label>
            </listing-attribute>
            <listing-attribute :shown="attribute_shown('utilities')" class="column">
                <template v-slot:label>{{ translate("Utilities") }}</template>
                <listing-features
                    type="VOCABULARY_UTILITIES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
        </div>
    </listing-panel>
</template>

<script>
import is_panel from "./IsPanel"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import ListingFeatures from "./ListingFeatures"
import ListingAttribute from "./ListingAttribute"

export default {
    name: "ListingAmenities",
    mixins: [is_panel],
    components: {
        FormInput,
        BaseLink,
        ListingFeatures,
        ListingAttribute
    },
    computed: {
        has_content () {
            return !!this.listing && (
                !this.listing.id ||
                this.attribute_shown("parking_spots") ||
                this.attribute_shown("computed_kitchen_count") ||
                this.features_shown("VOCABULARY_UTILITIES")
            )
        },
        total_parking_spots () {
            if(!this.listing) return 0
            return this.listing.garage_spots + this.listing.exterior_spots + this.listing.covered_spots
        }
    }
}
</script>
