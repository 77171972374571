<template>
    <listing-panel
        :id="id"
        :title="title"
    >
        <div class="columns">
            <div class="column col-4 col-lg-4 col-md-3 col-sm-12">
                <listing-attribute>
                    <template v-slot:label>{{ translate("Status") }}</template>
                    <form-dropdown
                        id="status_id"
                        name="status_id"
                        :value="listing.status_id"
                        :options="property_status"
                        :required="true"
                        :editable="is_overridable && !is_mls_record"
                        :error="has_error('status_id')"
                        @input="save"
                    />
                </listing-attribute>
            </div>
            <div class="column col-4 col-lg-4 col-md-6 col-sm-12">
                <listing-attribute>
                    <template v-slot:label>MLS&reg; #</template>
                    <form-mls-number-input
                        id="mls_id"
                        name="mls_id"
                        v-model="listing.mls_id"
                        :listing-id="listing.id || 0"
                        :relation-ids="relationIds"
                        :editable="is_my_record"
                        :required="false"
                    />
                </listing-attribute>
            </div>
            <div class="column col-4 col-lg-4 col-md-3 col-sm-12">
                <listing-attribute>
                    <template v-slot:label>{{ translate("Price") }}</template>
                    <form-input
                        id="price"
                        name="price"
                        :value="listing.price"
                        type="stripe"
                        glyph="dollar-sign"
                        glyph-position="left"
                        :min="1"
                        :required="true"
                        :editable="is_overridable && !is_mls_record"
                        :error="has_error('price')"
                        @input="save"
                    >
                        <template v-slot:empty-value>{{ (listing.price / 100) | nibnut.listing_price(listing.price_frequency) }}</template>
                        <template v-if="!!listing.price_frequency" v-slot:right_addon>
                            <span class="input-group-addon">
                                {{ listing.price_frequency }}
                            </span>
                        </template>
                    </form-input>
                </listing-attribute>
            </div>
        </div>
        <div class="columns">
            <div
                v-if="!listing_is_land_only(listing) || (is_overridable && !is_mls_record)"
                class="column col-4 col-lg-4 col-md-4 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>{{ translate("Bedrooms") }}</template>
                    <base-link
                        v-if="is_overridable"
                        href="#building-information"
                        class="form-label disabled-field"
                        @click.prevent.stop="navigate_to"
                    >
                        {{ listing.computed_bedroom_count | nibnut.number("0,0") }}
                    </base-link>
                    <label
                        v-else
                        class="form-label disabled-field"
                    >
                        {{ listing.computed_bedroom_count | nibnut.number("0,0") }}
                    </label>
                </listing-attribute>
            </div>
            <div
                v-if="!listing_is_land_only(listing) || (is_overridable && !is_mls_record)"
                class="column col-4 col-lg-4 col-md-4 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>{{ translate("Bathrooms") }}</template>
                    <base-link
                        v-if="is_overridable"
                        href="#building-information"
                        class="form-label disabled-field"
                        @click.prevent.stop="navigate_to"
                    >
                        {{ listing.computed_bathroom_count | nibnut.number("0,0") }}
                    </base-link>
                    <label
                        v-else
                        class="form-label disabled-field"
                    >
                        {{ listing.computed_bathroom_count | nibnut.number("0,0") }}
                    </label>
                </listing-attribute>
            </div>
            <div
                v-if="!listing_is_land_only(listing) || (is_overridable && !is_mls_record)"
                class="column col-4 col-lg-4 col-md-4 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>{{ translate("Year Built") }}</template>
                    <form-input
                        id="construction_year"
                        name="construction_year"
                        type="number"
                        :value="listing.construction_year"
                        :min="1700"
                        :required="true"
                        :editable="is_overridable && !is_mls_record"
                        :error="has_error('construction_year')"
                        @input="save"
                    />
                </listing-attribute>
            </div>
            <div
                v-if="!listing_is_land_only(listing) || (is_overridable && !is_mls_record)"
                class="column col-4 col-lg-4 col-md-3 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>
                        <span v-if="is_overridable && !is_mls_record">{{ translate("Size") }}</span>
                        <span v-else>{{ translate("Square Footage") }}</span>
                    </template>
                    <form-input
                        id="size"
                        name="size"
                        v-model="listing.size"
                        type="stripe"
                        :min="0"
                        :required="false"
                        :editable="is_overridable && !is_mls_record"
                        :error="has_error('size')"
                        @input="save"
                    >
                        <template v-slot:right_addon>
                            <form-dropdown
                                id="size_units"
                                name="size_units"
                                v-model="listing.size_units"
                                :options="size_units"
                                :required="true"
                                :editable="is_overridable && !is_mls_record"
                                @input="save"
                            />
                        </template>
                        <template v-slot:empty-value>
                            <listing-size-display
                                v-if="listing.computed_size"
                                :size="listing.computed_size"
                                :units="constants('units', 'UNIT_SQFT').id"
                            />
                        </template>
                    </form-input>
                </listing-attribute>
            </div>
            <div class="column col-4 col-lg-4 col-md-3 col-sm-12">
                <listing-attribute>
                    <template v-slot:label>
                        {{ translate("Lot Size") }}
                    </template>
                    <form-input
                        id="land_size"
                        name="land_size"
                        v-model="listing.land_size"
                        type="stripe"
                        :min="0"
                        :required="false"
                        :editable="is_overridable && !is_mls_record"
                        :error="has_error('land_size')"
                        @input="save"
                    >
                        <template v-slot:right_addon>
                            <form-dropdown
                                id="land_size_units"
                                name="land_size_units"
                                v-model="listing.land_size_units"
                                :options="land_size_units"
                                :required="true"
                                :editable="is_overridable && !is_mls_record"
                                @input="save"
                            />
                        </template>
                        <template v-slot:empty-value>
                            <listing-size-display
                                :size="listing.computed_land_size ? listing.computed_land_size : listing.land_size"
                                :units="listing.computed_land_size ? constants('units', 'UNIT_ACRES').id : listing.land_size_units"
                                :auto-convert="true"
                            />
                        </template>
                    </form-input>
                </listing-attribute>
            </div>
            <div
                :class="{ 'col-md-6': (!listing_is_land_only(listing) || (is_overridable && !is_mls_record)), 'col-md-9': !(!listing_is_land_only(listing) || (is_overridable && !is_mls_record)) }"
                class="column col-4 col-lg-4 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>
                        {{ translate("Property Type") }}
                    </template>
                    <form-dropdown
                        id="property_type"
                        name="property_type"
                        :value="listing.property_type_id"
                        :options="property_types"
                        :required="true"
                        :editable="is_overridable && !is_mls_record"
                        :error="has_error('property_type')"
                        @input="save"
                    >
                        <template v-slot:empty-value>
                            {{ term_path_value(listing.property_type_id) }}
                        </template>
                    </form-dropdown>
                </listing-attribute>
            </div>
            <div
                v-if="!listing_is_land_only(listing) || (is_overridable && !is_mls_record)"
                class="column col-4 col-lg-4 col-md-4 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>
                        {{ translate("Style") }}
                    </template>
                    <listing-features
                        type="VOCABULARY_ARCHITECTURAL_STYLES"
                        :term-ids="listing.term_ids"
                        :editable="is_overridable && !is_mls_record"
                        :all-errors="allErrors"
                        class="justify-content-end"
                    />
                </listing-attribute>
            </div>
            <div
                v-if="(is_overridable && !is_mls_record) || !!terms_all_active('VOCABULARY_VIEW_TYPES', listing.term_ids).length"
                class="column col-4 col-lg-4 col-md-6 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>
                        {{ translate("View") }}
                    </template>
                    <listing-features
                        type="VOCABULARY_VIEW_TYPES"
                        :term-ids="listing.term_ids"
                        :editable="is_overridable && !is_mls_record"
                        :all-errors="allErrors"
                        class="justify-content-end"
                    />
                </listing-attribute>
            </div>
            <div
                v-if="(is_overridable && !is_mls_record) || !!terms_all_active('VOCABULARY_WATERFRONT_FEATURES', listing.term_ids).length"
                class="column col-4 col-lg-4 col-md-6 col-sm-12"
            >
                <listing-attribute>
                    <template v-slot:label>{{ translate("Waterfront") }}</template>
                    <listing-features
                        type="VOCABULARY_WATERFRONT_FEATURES"
                        :term-ids="listing.term_ids"
                        :editable="is_overridable && !is_mls_record"
                        :all-errors="allErrors"
                        class="justify-content-end"
                    />
                </listing-attribute>
            </div>
        </div>
        <div v-if="!!listing && !!listing.public_remarks" class="mt-6">
            <form-textbox
                id="public_remarks"
                name="public_remarks"
                v-model="listing.public_remarks"
                size="lg"
                :editable="is_overridable && !!listing.public_remarks_overriden"
                :required="false"
                @input="save"
            >
                <template v-slot:read_only>
                    <label class="form-label disabled-field">
                        {{ listing.public_remarks }}
                    </label>
                    <div
                        v-if="is_overridable && !listing.public_remarks_overriden"
                        aria-live="polite"
                        class="form-input-hint text-right"
                    >
                        <default-button
                            size="sm"
                            @click.prevent="save(true, 'public_remarks_overriden')"
                        >
                            <open-icon glyph="pencil-alt" />
                            {{ translate("Override MLS Comments") }}
                        </default-button>
                    </div>
                </template>
                <template v-slot:label>
                    <span v-if="is_overridable && !!listing.public_remarks_overriden">{{ translate("Realtor Comments") }}</span>
                </template>
                <template v-slot:hint>
                    <default-button
                        v-if="is_overridable && !!listing.public_remarks_overriden"
                        size="sm"
                        :waiting="!!saving('public_remarks_overriden')"
                        :disabled="!!saving('public_remarks_overriden')"
                        class="float-right"
                        @click.prevent="save(false, 'public_remarks_overriden')"
                    >
                        <open-icon glyph="undo" />
                        {{ translate("Reset to Original MLS Comments") }}
                    </default-button>
                </template>
            </form-textbox>
        </div>
    </listing-panel>
</template>

<script>
import is_panel from "./IsPanel"
import string_utilities from "@/nibnut/mixins/StringUtilities"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import ListingAttribute from "./ListingAttribute"
import ListingFeatures from "./ListingFeatures"
import { FormMlsNumberInput, ListingSizeDisplay } from "@/custom/components"

export default {
    name: "ListingProperty",
    mixins: [is_panel, string_utilities],
    components: {
        FormInput,
        FormDropdown,
        FormTextbox,
        BaseLink,
        DefaultButton,
        OpenIcon,
        ListingAttribute,
        ListingFeatures,
        FormMlsNumberInput,
        ListingSizeDisplay
    },
    computed: {
        business_types () {
            return Object.values(this.constants("VOCABULARY_BUSINESS_TYPES"))
        },
        property_status () {
            return this.vocabulary("VOCABULARY_LISTING_STATUS")
        },
        status () {
            if(this.listing && this.listing.status_id) return this.property_status.find(term => term.id === this.listing.status_id) || {}
            return {}
        }
    }
}
</script>
