<template>
    <div class="listing-features columns">
        <div
            v-for="term in terms"
            :key="term.id"
            class="column col-auto"
        >
            <form-toggle-input
                v-if="editable"
                :id="`${type}-${term.id}`"
                name="term_id"
                type="checkbox"
                :value="!!term_active(term)"
                :required="false"
                :error="has_error('term_id')"
                @input="toggle(term)"
            >
                {{ term.name }}
            </form-toggle-input>
            <label v-else class="form-label">
                {{ term.name }}
            </label>
        </div>
    </div>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"
import addl_string_utilities from "@/custom/mixins/AddlStringUtilities"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"

export default {
    name: "ListingFeatures",
    mixins: [handles_errors, addl_string_utilities, addl_misc_utilities],
    components: {
        FormToggleInput
    },
    watch: {
        allErrors: "reset_errors"
    },
    methods: {
        reset_errors () {
            this.errors = this.allErrors
        },
        term_active (term, list = null) {
            return this.term_is_active(term, list || this.termIds)
        },
        toggle (term) {
            const term_ids = [...this.termIds]
            const index = term_ids.indexOf(term.id)
            if(index >= 0) term_ids.splice(index, 1)
            else term_ids.push(term.id)
            this.$emit("input", term_ids, "term_ids")
        }
    },
    computed: {
        title () {
            return this.constants("vocabularies", this.type).name
        },
        filtered_terms () {
            return this.terms_all_active(this.type, this.termIds)
        },
        terms () {
            if(this.editable) return this.vocabulary(this.type)
            return this.filtered_terms
        }
    },
    props: {
        type: {
            type: String,
            required: true
        },
        termIds: {
            type: Array,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        allErrors: {
            type: Object,
            default () {
                return null
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listing-features {
    text-align: left;
}
</style>
