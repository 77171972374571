<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        :title="title"
    >
        <documents-list
            :documents="documents"
            :editable="is_overridable"
            class="mb-4"
            @delete="confirm_clear_file(document_post_name($event))"
        />
        <upload-input
            v-if="is_overridable"
            url=""
            :name="document_post_name()"
            :alt="translate('Upload new document')"
            :accepts="upload_accepts(document_post_name())"
            :uploading="!!uploading[document_post_name()]"
            :uploaded="uploaded[document_post_name()] || 0"
            :deletable="false"
            glyph="plus"
            @upload="upload_file_list(document_post_name(), $event)"
            class="document-upload-zone"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="clear_file"
        />
    </listing-panel>
</template>
<script>
import is_attachments_panel from "./IsAttachmentsPanel"

import UploadInput from "@/nibnut/components/Inputs/UploadInput"
import DocumentsList from "@/custom/components/DocumentsList"

export default {
    name: "ListingDocuments",
    mixins: [is_attachments_panel],
    components: {
        UploadInput,
        DocumentsList
    },
    methods: {
        upload_file_post_name (name) {
            return this.document_post_name()
        },
        document_post_name (document = null) {
            if(!document) return "document_url"
            return `document_url_${document.id}`
        },
        document_from_post_name (name) {
            const id = parseInt(name.replace(/^document_url_/, ""))
            return this.documents.find(document => document.id === id)
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(pdf|docx?|mp4|mpeg|webm|txt|csv)$/i)
            return "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/*,text/plain,text/csv,.pdf,.doc,.docx,.mp4,.mpeg,.webm,.csv,.txt"
        },
        upload_data (name, action, file = null) {
            const document = this.document_from_post_name(name)
            if(file) return this.upload_attachment_data(this.document_type_to_constant(file.type), document)
            return this.upload_attachment_data(null, document)
        },
        confirm_clear_file (name) {
            if(this.holding_mode) {
                if(this.held_images[name]) {
                    URL.revokeObjectURL(this.held_images[name].src)
                    this.$delete(this.held_images, name, null)
                }
                this.$delete(this.held, name, null)
            } else {
                this.confirming_clear_file = name
                this.confirm(
                    {
                        title: this.$root.translate("Delete document"),
                        message: this.$root.translate("Do you really want to delete the document \"{name}\"? Make sure you have a copy if you think you'll need it again some day.", { name }),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete")
                    },
                    "clear-file"
                )
            }
        },
        upload_detached (name, response) {
            this.done_confirming()
            const document = this.document_from_post_name(name)
            this.$store.dispatch("SWEEP", { entity: "attachment", id: document.id }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        documents () {
            const type_exceptions = [this.constants("attachment_types", "TYPE_IMAGE").id, this.constants("attachment_types", "TYPE_BANNER").id]
            return this.attachments_except_types(type_exceptions)
        },
        has_content () {
            return !!this.listing && (
                !this.listing.id ||
                this.is_overridable ||
                !!this.documents.length
            )
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#documents {
    .document-upload-zone {
        .empty {
            padding: $unit-2;
        }
    }
}
</style>
