<template>
    <section
        v-if="!!listing && is_at_least_realtor_for_listing(listing)"
        id="listing-sellers"
        class="p-2"
    >
        <div
            v-for="seller in sellers"
            :key="seller.id"
            class="mb-2"
        >
            <default-button
                flavor="link"
                :color="listing.is_main_listing ? 'primary' : 'light'"
                :title="listing.is_main_listing ? translate('This is the main listing for this seller') : translate('Click to make this the main listing for this seller')"
                size="sm"
                @click.prevent.stop="$emit('input', !listing.is_main_listing, 'is_main_listing')"
            >
                <open-icon glyph="flag" />
            </default-button>
            {{ seller.name }}
            <span v-if="seller.email">
                &bull; <mailto-link
                    :value="seller.email"
                />
            </span>
            <span v-if="seller.phone">
                &bull; <mailto-link
                    :value="seller.phone"
                    filter="telto"
                >
                    <open-icon glyph="phone" /> {{ seller.phone }}
                </mailto-link>
            </span>
            <span v-if="seller.mobile">
                &bull; <mailto-link
                    :value="seller.mobile"
                    filter="telto"
                >
                    <open-icon glyph="mobile" /> {{ seller.mobile }}
                </mailto-link>
            </span>
            <default-button
                flavor="link"
                size="sm"
                class="ml-2"
                @click.prevent.stop="edit_seller(seller)"
            >
                <open-icon glyph="pen" />
            </default-button>
            <default-button
                flavor="link"
                size="sm"
                class="ml-2"
                @click.prevent.stop="unlink_seller(seller)"
            >
                <open-icon glyph="trash" />
            </default-button>
            <impersonate-button
                :uuid="seller.uuid"
                :listing-uuid="listing.uuid"
                :route="{ name: 'home', params: { uuid: listing.uuid } }"
                flavor="link"
                :placeholder="translate('Preview as seller')"
                :block="false"
                size="sm"
                class="ml-2"
            >
                <open-icon glyph="external-link-square-alt" />
            </impersonate-button>
            <span class="ml-4 text-gray">
                <span v-if="!seller.last_login_at">
                    {{ translate("Never logged in") }}
                    <default-button
                        :title="$root.translate('Re-send invitation')"
                        flavor="link"
                        size="sm"
                        class="ml-2"
                        @click.prevent.stop="resend_invitation(seller)"
                    >
                        <open-icon glyph="share" />
                    </default-button>
                </span>
                <span v-else>
                    {{ translate("1 login:::{n} logins", { n: seller.login_count }, seller.login_count) }}
                    &bull;
                    {{ translate("Last login") }}:&nbsp;{{ seller.last_login_at | nibnut.date("MMM, DD") }}&nbsp;@&nbsp;{{ seller.last_login_at | nibnut.date("HH:mm") }}
                </span>
            </span>
        </div>
        <default-button
            :flavor="sellers.length ? 'link' : 'normal'"
            :block="!sellers.length"
            :size="sellers.length ? 'sm' : 'md'"
            :class="{ 'px-0': !!sellers.length }"
            @click.prevent.stop="start_assigning(null)"
        >
            <span v-if="!sellers.length">{{ translate("Assign to a seller...") }}</span>
            <span v-else>{{ translate("Assign to another seller...") }}</span>
        </default-button>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />
        <modal-dialog
            id="listing-seller-dialog"
            :show.sync="assign"
        >
            <template v-slot:title><span class="h5">{{ translate("Seller of {address}", { address: address_one_line(listing) }) }}</span></template>
            <base-form
                :has-required-fields="true"
                @submit.prevent.stop
            >
                <loader
                    v-if="loading"
                    class="p-centered"
                />
                <div v-else-if="!current_seller">
                    <form-select
                        id="seller-query"
                        name="query"
                        :value="query"
                        data-source="user"
                        label-field="label"
                        :ad-hoc="false"
                        :show-all="false"
                        :required="true"
                        :filter-fields="['email']"
                        :data-source-additional-data="{ignore: ignored_user_ids}"
                        @input="pick_seller($event)"
                    >
                        <template v-slot:label>{{ translate("Search by First name, Last name or email address") }}</template>
                    </form-select>
                    <div class="divider text-center my-8" :data-content="translate('OR')"></div>
                    <default-button
                        :block="true"
                        class="mb-8"
                        @click.prevent.stop="pick_seller(0)"
                    >
                        {{ translate("Create a new seller") }}
                    </default-button>
                </div>
                <div v-else class="columns">
                    <form-input
                        id="seller-first_name"
                        name="first_name"
                        v-model="current_seller.first_name"
                        :required="true"
                        :saving="saving('first_name')"
                        :error="has_error('first_name')"
                        class="column col-6 col-sm-12"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("First Name") }}</template>
                    </form-input>
                    <form-input
                        id="seller-last_name"
                        name="last_name"
                        v-model="current_seller.last_name"
                        :required="true"
                        :saving="saving('last_name')"
                        :error="has_error('last_name')"
                        class="column col-6 col-sm-12"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Last Name") }}</template>
                    </form-input>

                    <form-input
                        id="seller-email"
                        name="email"
                        type="email"
                        v-model="current_seller.email"
                        :required="true"
                        :saving="saving('email')"
                        :error="has_error('email')"
                        class="column col-4 col-lg-12"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Email") }}</template>
                    </form-input>
                    <form-input
                        id="seller-phone"
                        name="phone"
                        type="tel"
                        v-model="current_seller.phone"
                        :required="true"
                        :saving="saving('phone')"
                        :error="has_error('phone')"
                        class="column col-4 col-lg-12"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Phone") }}</template>
                    </form-input>
                    <form-input
                        id="seller-mobile"
                        name="mobile"
                        type="tel"
                        v-model="current_seller.mobile"
                        :required="true"
                        :saving="saving('mobile')"
                        :error="has_error('mobile')"
                        class="column col-4 col-lg-12"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Mobile") }}</template>
                    </form-input>
                    <div v-if="!!current_seller && !current_seller.id" class="column col-12">
                        <invitation-expiries
                            id="seller-expires_in"
                            name="expires_in"
                            v-model="current_seller.expires_in"
                            :required="true"
                            :error="has_error('expires_in')"
                        />
                    </div>
                </div>
            </base-form>

            <template v-slot:footer>
                <div v-if="current_seller && !current_seller.id" class="columns">
                    <div class="column">
                        <default-button
                            :block="true"
                            @click.prevent="current_seller = null"
                        >
                            <open-icon glyph="chevron-left" /> {{ translate("Back") }}
                        </default-button>
                    </div>
                    <div class="column">
                        <default-button
                            :disabled="assigning"
                            :block="true"
                            @click.prevent="assign = false"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                    </div>
                    <div class="column">
                        <default-button
                            :waiting="assigning"
                            :disabled="assigning"
                            color="primary"
                            :block="true"
                            @click.prevent.stop="record_save"
                        >
                            {{ translate("Assign") }}
                        </default-button>
                    </div>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </section>
</template>

<script>
import { mapGetters } from "vuex"

import { handles_saving, string_utilities, is_user_inviter, confirms } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import { ModalDialog, BaseForm, FormSelect, FormInput, InvitationExpiries, MailtoLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import { Loader, ImpersonateButton } from "@/custom/components"

export default {
    name: "ListingSeller",
    mixins: [handles_saving, string_utilities, is_user_inviter, confirms, addl_profile_utilities],
    components: {
        ModalDialog,
        BaseForm,
        FormSelect,
        FormInput,
        InvitationExpiries,
        MailtoLink,
        DefaultButton,
        OpenIcon,
        Loader,
        ImpersonateButton
    },
    methods: {
        start_assigning (seller = null) {
            this.current_seller = seller
            this.query = ""
            this.assign = true
        },
        pick_seller (user_id) {
            this.loading = true
            if(user_id) {
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: this.entity,
                        id: user_id
                    }
                ).then(record => {
                    this.current_seller = record
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            } else {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: this.entity }
                ).then(record => {
                    record.expires_in = this.app_context.settings.default_invitation_delay
                    this.current_seller = record
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            }
        },
        save (value, field) {
            if(this.current_seller) {
                if(this.current_seller[field] !== value) this.current_seller[field] = value
                if(this.current_seller.id) return this.save_field_for_record_id(this.entity, this.current_seller.id, this.current_seller[field], field)
            }
            return Promise.resolve()
        },
        record_save () {
            if(this.current_seller) {
                this.assigning = true
                const wrapup = () => {
                    this.save_data_for_record_id("listing", this.listing.id, { seller_id: this.current_seller.id }).then(() => {
                        this.assigning = false
                        this.assign = false
                    })
                }
                if(!this.current_seller.id) {
                    this.save_data_for_record_id(this.entity, 0, this.current_seller).then(record => {
                        this.current_seller = record
                        wrapup()
                    })
                } else {
                    wrapup()
                }
            }
        },
        edit_seller (seller) {
            this.start_assigning(seller)
        },
        unlink_seller (seller) {
            if(seller) {
                this.confirm(
                    {
                        type: "error",
                        title: this.translate("Unlink Seller"),
                        message: this.translate("Do you really want to unlink '{name}' from this listing? The seller's account will not be deleted, but the seller will no longer have access to this listing.", { name: seller.name }),
                        cancel: this.translate("Cancel"),
                        ok: this.translate("Unlink"),
                        seller
                    },
                    "delete-record"
                )
            }
        },
        confirmed () {
            if(this.confirmation_props.seller) {
                this.save_data_for_record_id("listing", this.listing.id, { seller_id: this.confirmation_props.seller.id }).then(() => {
                    this.done_confirming()
                })
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        sellers () {
            return this.entity_records("user", this.listing.seller_ids)
        },
        ignored_user_ids () {
            return [
                ...this.listing.seller_ids,
                this.profile.id
            ]
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "user",
            query: "",
            assign: false,
            loading: false,
            current_seller: null,
            assigning: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

section#listing-sellers {
    @media (max-width: $size-md) {
        margin-top: $unit-8;
    }
}
</style>
