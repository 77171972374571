<template>
    <div
        v-if="!!listing"
        class="columns listing-statistics"
    >
        <div
            v-if="listing.is_available"
            class="column col-12 mb-4"
        >
            <div class="card card-overflow-visible">
                <div class="card-header">
                    <div class="card-title h5">
                        {{ translate("Chance Of Selling") }}
                        <router-link
                            v-if="is_at_least_realtor_for_listing(listing)"
                            :to="{ name: 'listing.intelligence', params: {id: listing.id } }"
                            v-slot="{ href, navigate }"
                            custom
                        >
                            <base-link
                                ref="link"
                                :href="href"
                                :title="translate('Setup Chance of Selling Parameters...')"
                                class="btn btn-sm float-right"
                                @click.stop="navigate"
                            >
                                <open-icon glyph="pencil-alt" />
                            </base-link>
                        </router-link>
                    </div>
                </div>
                <div class="card-body">
                    <chance-of-selling-graphs
                        :listing="listing"
                        :editable="false"
                    >
                        <default-button
                            flavor="link"
                            :block="true"
                            size="sm"
                            @click.prevent.stop="viewing_chance_of_selling = !viewing_chance_of_selling"
                        >
                            <open-icon glyph="tachometer-alt" /> {{ translate("Selling Gauge") }}
                        </default-button>
                    </chance-of-selling-graphs>
                </div>
            </div>
        </div>
        <div
            v-if="listing.is_available && !!statistics.length"
            class="column col-12 mb-4 columns column-grid"
        >
            <div
                v-for="statistic in statistics"
                :key="statistic.id"
                class="column col-lg-auto col-sm-12 mb-2"
            >
                <div class="card card-overflow-visible text-center">
                    <div class="card-header">
                        <div class="card-title h6 no-wrap">
                            {{ statistic.title }}
                            <tooltip-widget
                                v-if="statistic.id === 'price-changes'"
                                :content="price_changes_list"
                                glyph="info-circle"
                                class="text-small"
                            />
                        </div>
                    </div>
                    <div
                        class="card-body"
                    >
                        <div v-if="statistic.sub_title" class="text-gray text-small">
                            {{ statistic.sub_title }}
                        </div>
                        <div class="h1">
                            {{ statistic.value | nibnut.number("0") }}
                        </div>
                        <div
                            :class="{ 'd-invisible': !statistic.total_value }"
                            class="text-gray text-small"
                        >
                            {{ statistic.total_value | nibnut.number("0,0") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            v-for="statistic in statistics"
            :key="statistic.id"
            class="column col-sm-12 mb-4"
        >
            <div class="card card-overflow-visible text-center">
                <div class="card-header">
                    <div class="card-title h5">
                        {{ statistic.title }}
                        <default-button
                            v-if="statistic.id === 'price-changes'"
                            flavor="link"
                            :data-tooltip="price_changes_list"
                            size="sm"
                            class="tooltip"
                        >
                            <open-icon glyph="info-circle" size="lg" />
                        </default-button>
                    </div>
                </div>
                <div class="card-body">
                    <div
                        :class="{ 'pb-5': !statistic.sub_title }"
                        class="h1"
                    >
                        {{ statistic.value | nibnut.number("0") }}
                    </div>
                    <div v-if="statistic.sub_title" class="text-gray text-small">
                        {{ statistic.sub_title }}
                    </div>
                </div>
            </div>
        </div>
        <modal-dialog
            id="chance-of-selling-dialog"
            :show.sync="viewing_chance_of_selling"
            class="modal-lg"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Chances Of Selling") }}</span>
            </template>
            <div>
                {{ translate('Based on our system algorithms we take into account competitive listings number of sales inventory ratios and number of showings, time and other factors are analyzed to determine the chance of selling this is intended as a general guage and many other economic factors effect selling. Your Realtor will advise on best strategy based on your selling goals') }}
                <chance-of-selling-graphs
                    v-if="!!listing"
                    :listing="listing || {}"
                    class="mt-8"
                />
            </div>
        </modal-dialog>
    </div>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import displays_chart from "@/custom/mixins/DisplaysChart"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import TooltipWidget from "@/custom/components/TooltipWidget"
import ChanceOfSellingGraphs from "@/custom/components/ChanceOfSellingGraphs"

export default {
    name: "Statistics",
    mixins: [addl_profile_utilities, displays_chart],
    components: {
        ModalDialog,
        BaseLink,
        DefaultButton,
        OpenIcon,
        TooltipWidget,
        ChanceOfSellingGraphs
    },
    computed: {
        chance_of_selling_col_count () {
            if(!this.listing) return 1
            if(!this.listing.chance_of_selling_graph_ids) return 3
            return this.listing.chance_of_selling_graph_ids.length + 1
        },
        statistics () {
            const relisted_on = this.listing.quick_statistics.relisted_on ? this.nibnut_filter("nibnut.date", [this.listing.quick_statistics.relisted_on]) : null
            const since = relisted_on ? this.translate("since {date}", { date: relisted_on }) : null
            const statistics = [
                { id: "days-on-market", title: this.translate("DOM"), value: this.listing.quick_statistics.dom.current, sub_title: since, total_value: (this.listing.quick_statistics.dom.current !== this.listing.quick_statistics.dom.total) ? this.listing.quick_statistics.dom.total : 0 }
            ]
            if(this.listing.quick_statistics.views_count && this.listing.quick_statistics.views_count.current && this.is_at_least_seller_for_listing(this.listing)) {
                statistics.push({ id: "views", title: this.translate("Views"), value: this.listing.quick_statistics.views_count.current, sub_title: since, total_value: (this.listing.quick_statistics.views_count.current !== this.listing.quick_statistics.views_count.total) ? this.listing.quick_statistics.views_count.total : 0 })
            }
            if(this.listing.computed_price_changes && this.listing.quick_statistics.price_changes.length && this.is_at_least_seller_for_listing(this.listing)) statistics.push({ id: "price-changes", title: this.translate("Price Changes"), value: this.listing.quick_statistics.price_changes.length })
            if(this.listing.quick_statistics.showings_count && this.listing.quick_statistics.showings_count.current) {
                statistics.push({ id: "showings", title: this.translate("Showings"), value: this.listing.quick_statistics.showings_count.current, sub_title: since, total_value: (this.listing.quick_statistics.showings_count.current !== this.listing.quick_statistics.showings_count.total) ? this.listing.quick_statistics.showings_count.total : 0 })
            }
            // if(this.listing.quick_statistics.avg_feedback_rating) statistics.push({ id: "avg-feedback-rating", title: this.translate("Feedback (Avg)"), value: this.listing.quick_statistics.avg_feedback_rating })
            if(this.listing.quick_statistics.comparables_count) statistics.push({ id: "comparables", title: this.translate("Comparables"), value: this.listing.quick_statistics.comparables_count })
            if(this.listing.quick_statistics.sales_since) {
                const days_count = this.listing.quick_statistics.sales_since
                statistics.push({ id: "sales", title: this.translate("Sales"), value: this.listing.quick_statistics.sales_count, sub_title: this.translate("last {days_count} days", { days_count }) })
            }

            if(this.listing.quick_statistics.moi) statistics.push({ id: "moi", title: this.translate("Inventory"), value: this.listing.quick_statistics.moi, sub_title: this.translate("in months") })
            if(this.listing.quick_statistics.avg_dom) statistics.push({ id: "avg_dom", title: this.translate("Avg DOM"), value: this.listing.quick_statistics.avg_dom })

            return statistics
        },
        price_changes_list () {
            return this.listing.quick_statistics.price_changes.join("\n")
        }
    },
    props: {
        listing: {
            type: Object,
            default () {
                return null
            }
        }
    },
    data () {
        return {
            entity: "listing",
            viewing_chance_of_selling: false
        }
    }
}
</script>

<style lang="scss">
.listing-statistics {
    .popover-container {
        width: 50vw;
    }
    .card {
        & > .card-header {
            & > .card-title {
                white-space: nowrap;
            }
        }
        & > .card-body {
            & > .h1 {
                font-size: 4rem;
            }
        }
    }
    .column-grid .card {
        & > .card-body {
            position: relative;
            padding: 0.2rem;

            & > .text-small:first-child {
                position: absolute;
                top: -0.4em;
                left: 50%;
                transform: translate(-50%, 0);
                white-space: nowrap;
                overflow: hidden;
            }
            & > .h1 {
                font-size: 1.75rem;
            }
        }
    }
}
</style>
