import is_editor from "./IsEditor"
import handles_saving from "@/nibnut/mixins/HandlesSaving"

import ListingPanel from "./ListingPanel"

const is_panel = {
    mixins: [is_editor, handles_saving],
    components: {
        ListingPanel
    },
    mounted () {
        this.reset_ui()
    },
    watch: {
        "listing.id": "reset_ui",
        allErrors: "reset_errors"
    },
    methods: {
        reset_errors () {
            this.errors = this.allErrors
        },
        reload_shell () {
            if(this.is_overridable) {
                this.$store.dispatch("FETCH_RECORD_SHELL", { entity: this.entity }).then(record => {
                    this.shell = record
                })
            }
        },
        reset_ui () {
            this.grab_record_id()
            this.visibility_may_have_changed()
        },
        grab_record_id () {
            this.record_id = this.listing ? this.listing.id : 0
        },
        toggle_lifestyle (lifestyle) {
            this.toggle_tag(this.listing.term_ids, lifestyle)
            this.save(this.listing.term_ids, "term_ids")
        },
        save (value, field) {
            if(this.listing) {
                if(this.listing[field] !== value) this.listing[field] = value
                if(this.listing.id) return this.save_field(this.listing[field], field)
            }
            return Promise.resolve()
        },
        attribute_shown (field) {
            if(this.is_overridable) return !!this.listing
            return !!this.listing && (!!this.is_overridable || !!this.listing[field])
        },
        features_shown (vocabulary) {
            const has_terms = !!this.vocabulary(vocabulary).length
            return !!this.listing && ((!!this.is_overridable && has_terms) || !!this.terms_all_active(vocabulary, this.listing.term_ids).length)
        },
        visibility_may_have_changed () {
            this.$emit("shown", this.has_content)
        },
        navigate_to (event) {
            this.scroll_to(document.getElementById(event.target.hash.replace(/#/, "")))
        },
        add_record () {
            this.$emit("add", this.entity, { ...this.shell }, this.record_added)
        },
        record_added () {
        }
    },
    computed: {
        has_content () {
            return true
        }
    },
    props: {
        ...ListingPanel.props,
        listing: {
            type: Object,
            required: true
        },
        heldRecords: {
            default () {
                return null
            }
        },
        wrapup: {
            default: null
        },
        relationIds: {
            type: Array,
            default () {
                return []
            }
        },
        allErrors: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            entity: "listing",
            shell: null
        }
    }
}

export default is_panel
