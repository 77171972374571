<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        :title="title"
    >
        <ul>
            <li
                v-for="(link, index) in listing.links"
                :key="index"
                class="columns"
            >
                <form-url-input
                    :id="`url-${index}`"
                    name="url"
                    :value="link"
                    :required="false"
                    class="column"
                    @input="save_url($event, index)"
                />
                <default-button
                    v-if="is_overridable"
                    flavor="link"
                    @click.prevent="link_remove(index, link)"
                >
                    <open-icon glyph="trash" />
                </default-button>
            </li>
            <li v-if="is_overridable">
                <default-button
                    v-if="!setting_up"
                    :block="true"
                    @click.prevent="link_editor_show"
                >
                    <open-icon glyph="plus" />
                    {{ translate("Add another link...") }}
                </default-button>
                <form-url-input
                    v-else
                    id="url-new"
                    name="url"
                    v-model="url"
                    :required="false"
                />
                <div
                    v-if="setting_up"
                    class="columns"
                >
                    <div class="column">
                        <default-button
                            color="error"
                            :block="true"
                            @click.prevent="link_editor_done(false)"
                        >
                            <open-icon glyph="times" />
                        </default-button>
                    </div>
                    <div class="column">
                        <default-button
                            color="success"
                            :block="true"
                            @click.prevent="link_editor_done(true)"
                        >
                            <open-icon glyph="check" />
                        </default-button>
                    </div>
                </div>
            </li>
        </ul>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </listing-panel>
</template>
<script>
import is_panel from "./IsPanel"
import { confirms } from "@/nibnut/mixins"

import FormUrlInput from "@/custom/components/FormUrlInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ListingLinks",
    mixins: [is_panel, confirms],
    components: {
        FormUrlInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        save_url (value, index = -1) {
            const links = this.listing.links ? [...this.listing.links] : []
            if((index >= 0) && (index < links.length)) links[index] = value
            else links.push(value)
            this.save(links, "links")
        },
        link_editor_show () {
            this.url = ""
            this.setting_up = true
        },
        link_editor_done (save) {
            if(save && this.url) this.save_url(this.url)
            this.setting_up = false
        },
        link_remove (index, url) {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Link"),
                    message: this.translate("Do you really want to remove the link '{url}'?", { url }),
                    cancel: this.translate("Cancel"),
                    ok: this.translate("Delete"),
                    index
                },
                "delete-link"
            )
        },
        confirmed () {
            if(this.confirmation_props.index >= 0) {
                const links = [...this.listing.links]
                links.splice(this.confirmation_props.index, 1)
                this.save(links, "links")
            }
            this.done_confirming()
        }
    },
    computed: {
        has_content () {
            return !!this.listing && (
                !this.listing.id ||
                this.is_overridable ||
                (!!this.listing.links && !!this.listing.links.length)
            )
        }
    },
    data () {
        return {
            url: "",
            setting_up: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#documents {
    .document-upload-zone {
        .empty {
            padding: $unit-2;
        }
    }
}
</style>
