<template>
    <div v-if="shown" class="listing-attribute">
        <div class="form-group">
            <div class="columns">
                <div class="column col-auto">
                    <label
                        class="form-label"
                    >
                        <slot name="label"></slot>
                    </label>
                </div>
                <div
                    :class="{ 'col-12': vertical, 'text-right': !vertical }"
                    class="column text-right"
                >
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

export default {
    name: "ListingAttribute",
    mixins: [is_nibnut_component],
    props: {
        shown: {
            type: Boolean,
            default: true
        },
        vertical: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listing-attribute {
    height: 100%;
    &.column {
        height: auto;
    }

    & > .form-group {
        height: 100%;
        padding-top: $unit-2;
        padding-bottom: $unit-2;
        border-bottom: $border-width solid $border-color;

        .form-label {
            padding: 0;
        }
        & > .columns {
            & > .column:first-child {
                .form-label {
                    font-weight: bold;
                    white-space: nowrap;
                }
            }
            & > .column:last-child {
                .form-group {
                    margin-left: auto;
                }
            }
        }
    }

    & .listing-attribute > .form-group {
        border-bottom: 0;
        margin: 0;
        padding: 0;
    }
}
</style>
