<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        title=""
    >
        <h5>
            {{ translate("Rooms") }}
            <span class="ml-8">
                <open-icon glyph="bath" />
                {{ bathrooms.length | nibnut.number("0,0") }}
            </span>
            <span class="ml-8">
                <open-icon glyph="bed" />
                {{ bedrooms.length | nibnut.number("0,0") }}
            </span>
        </h5>
        <data-table
            id="listing-rooms"
            :columns="visible_columns"
            :rows="filtered_rows"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="is_overridable && !is_mls_record"
            :searchable="false"
            entity-name="room:::rooms"
            @add="add_record"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6 v-if="row_option_for_field(row, 'type_id')">
                    {{ row_option_for_field(row, 'type_id').name }}
                </h6>
                <h6 v-if="row_option_for_field(row, 'level_id')" class="text-gray">
                    {{ row_option_for_field(row, 'level_id').name }}
                </h6>
                <form-input
                    :id="`dimensions-${row.id}`"
                    name="dimensions"
                    v-model="row.dimensions"
                    :required="false"
                    :editable="is_overridable && !is_mls_record"
                    :error="has_error('dimensions')"
                    @input="save_room(row, ...arguments)"
                >
                    <template v-slot:label>{{ translate("Dimensions") }}</template>
                </form-input>
                <default-button
                    v-if="is_overridable && !is_mls_record"
                    color="error"
                    size="sm"
                    @click.prevent="confirm_room_delete(row)"
                >
                    <open-icon glyph="minus-circle" />
                    {{ translate("Delete") }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <form-dropdown
                    v-if="(field === 'type_id') || (field === 'level_id')"
                    :id="field"
                    :name="field"
                    v-model="row[field]"
                    :options="options_for_field(field)"
                    :required="true"
                    :editable="is_overridable && !is_mls_record"
                    :error="has_error(field)"
                    @input="save_room(row, ...arguments)"
                />
                <div v-else class="d-flex">
                <form-input
                        :id="`${field}-${row.id}`"
                        :name="field"
                        v-model="row[field]"
                        :required="false"
                        :editable="is_overridable && ((field === 'dimensions') || !is_mls_record)"
                        :error="has_error(field)"
                        class="flex-grow mb-0"
                        @input="save_room(row, ...arguments)"
                    />
                    <default-button
                        v-if="is_overridable && !is_mls_record && (field === 'dimensions')"
                        flavor="link"
                        color="error"
                        class="flex-static ml-2"
                        @click.prevent="confirm_room_delete(row)"
                    >
                        <open-icon glyph="minus-circle" />
                    </default-button>
                </div>
            </template>
        </data-table>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </listing-panel>
</template>

<script>
import is_panel from "./IsPanel"
import is_data_table_source from "@/nibnut/mixins/IsDataTableSource"
import confirms from "@/nibnut/mixins/Confirms"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import DataTable from "@/nibnut/components/DataTable/DataTable"

export default {
    name: "ListingBuilding",
    mixins: [is_panel, is_data_table_source, confirms],
    components: {
        FormInput,
        FormDropdown,
        DefaultButton,
        OpenIcon,
        DataTable
    },
    mounted () {
        this.reload_shell()
    },
    watch: {
        profile_id: "reload_shell",
        heldRecords: "reset_counters"
    },
    methods: {
        options_for_field (field) {
            if(field === "type_id") return this.vocabulary("VOCABULARY_ROOM_TYPES")
            if(field === "level_id") return this.vocabulary("VOCABULARY_HOUSE_LEVELS")
            return []
        },
        count_rooms_of_type (filter) {
            const term_ids = this.options_for_field("type_id").filter(filter).map(term => term.id)
            return this.rooms.filter(room => term_ids.indexOf(room.type_id) >= 0)
        },
        save_room (room, value, field) {
            if(room) {
                if(room[field] !== value) room[field] = value
                if(room.id > 0) return this.save_field_for_record_id(this.entity, room.id, room[field], field)
            }
        },
        record_added () {
            this.refresh()
        },
        row_option_for_field (row, field) {
            return this.options_for_field(field).find(term => term.id === row[field])
        },
        reset_counters () {
            this.set_state_values({
                total: this.rows.length,
                found: this.filtered ? this.filtered_rows.length : this.rows.length
            })
        },
        room_delete () {
            if(this.action_room) {
                if(this.action_room.id < 0) this.$emit("delete", this.entity, this.action_room)
                else {
                    this.$store.dispatch(
                        "RECORD_DELETE",
                        {
                            entity: this.entity,
                            id: this.action_room.id
                        }
                    ).catch(this.receive_error)
                }
            }
        },
        confirm_room_delete (room) {
            this.action_room = room
            if(room.id < 0) this.room_delete()
            else {
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Delete Room"),
                        message: this.$root.translate("Do you really want to delete the {type}, {level} room? There is no undo...", { type: this.row_option_for_field(room, "type_id").name, level: this.row_option_for_field(room, "level_id").name }),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete")
                    },
                    "delete-room"
                )
            }
        },
        confirmed () {
            this.room_delete()
            this.done_confirming()
        }
    },
    computed: {
        rooms () {
            if(!this.listing.id && !!this.heldRecords) return Object.values(this.heldRecords)
            return this.entity_records(this.entity).filter(room => room.listing_id === this.listing.id)
        },
        rows () {
            return this.rooms
        },
        bathrooms () {
            return this.listing.computed_bathroom_count || this.count_rooms_of_type(term => !!term.slug && !!term.slug.match(/(ensuite|bath)/i))
        },
        bedrooms () {
            return this.listing.computed_bedroom_count || this.count_rooms_of_type(term => !!term.slug && !!term.slug.match(/bed/i))
        },
        has_content () {
            return !!this.listing && (
                !this.listing.id ||
                this.listing.size
            )
        }
    },
    data () {
        return {
            entity: "room",
            columns: {
                type_id: { label: "Room Type", sort: false, type: "alpha" },
                level_id: { label: "Level", sort: false, type: "alpha" },
                dimensions: { label: "Dimensions", sort: false, type: "alpha" }
            },

            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            action_room: null
        }
    }
}
</script>
