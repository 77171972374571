<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        :title="title"
    >
        <div class="columns by-3">
            <listing-attribute :vertical="true" :shown="features_shown('VOCABULARY_INTERIOR_FEATURES')" class="column">
                <template v-slot:label>{{ translate("Interior") }}</template>
                <listing-features
                    type="VOCABULARY_INTERIOR_FEATURES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
            <listing-attribute :vertical="true" :shown="features_shown('VOCABULARY_APPLIANCES')" class="column">
                <template v-slot:label>{{ translate("Appliances") }}</template>
                <listing-features
                    type="VOCABULARY_APPLIANCES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
            <listing-attribute :vertical="true" :shown="features_shown('VOCABULARY_LAUNDRY_FEATURES')" class="column">
                <template v-slot:label>{{ translate("Laundry") }}</template>
                <listing-features
                    type="VOCABULARY_LAUNDRY_FEATURES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
            <listing-attribute :vertical="true" :shown="features_shown('VOCABULARY_COOLING_SOURCES')" class="column">
                <template v-slot:label>{{ translate("Cooling") }}</template>
                <listing-features
                    type="VOCABULARY_COOLING_SOURCES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
            <listing-attribute :vertical="true" :shown="features_shown('VOCABULARY_HEATING_SOURCES')" class="column">
                <template v-slot:label>{{ translate("Heating") }}</template>
                <listing-features
                    type="VOCABULARY_HEATING_SOURCES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
            <listing-attribute :vertical="true" :shown="features_shown('VOCABULARY_FIREPLACE_TYPES')" class="column">
                <template v-slot:label>{{ translate("Fireplaces") }}</template>
                <listing-features
                    type="VOCABULARY_FIREPLACE_TYPES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                />
            </listing-attribute>
        </div>
    </listing-panel>
</template>
<script>
import is_panel from "./IsPanel"

import ListingFeatures from "./ListingFeatures"
import ListingAttribute from "./ListingAttribute"

export default {
    name: "ListingInterior",
    mixins: [is_panel],
    components: {
        ListingFeatures,
        ListingAttribute
    },
    computed: {
        has_content () {
            return !!this.listing && (
                !this.listing.id ||
                this.features_shown("VOCABULARY_INTERIOR_FEATURES") ||
                this.features_shown("VOCABULARY_APPLIANCES") ||
                this.features_shown("VOCABULARY_LAUNDRY_FEATURES") ||
                this.features_shown("VOCABULARY_COOLING_SOURCES") ||
                this.features_shown("VOCABULARY_HEATING_SOURCES") ||
                this.features_shown("VOCABULARY_FIREPLACE_TYPES")
            )
        }
    }
}
</script>
