import { addl_profile_utilities } from "@/custom/mixins"
import displays_listing from "@/custom/mixins/DisplaysListing"

const is_editor = {
    mixins: [addl_profile_utilities, displays_listing],
    computed: {
        editor_record () {
            return this.record || this.listing
        },
        is_new_record () {
            const record = this.editor_record
            return !!record && !record.id
        },
        is_my_record () {
            const record = this.editor_record
            return !!record && !!this.profile && !!this.is_at_least_realtor_for_listing(record) // eventually, should be considered "mine" if realtor is me or one of my team
        },
        is_mls_record () {
            const record = this.editor_record
            return !record || !!record.mls_id
        },
        is_overridable () {
            return (this.is_my_record || !this.is_mls_record) && !!this.profile && !this.profile.impersonated
        }
    }
}

export default is_editor
