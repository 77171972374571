<template>
    <div v-if="!!listing">
        <h6>
            <slot name="title"></slot>
            <default-button
                v-if="overridable && (images.length > 1) && !featuredOnly"
                flavor="link"
                size="sm"
                :title="sorting ? translate('Stop Reordering Images') : translate('Reorder Images')"
                class="ml-2"
                @click="sorting = !sorting"
            >
                <open-icon v-if="sorting" :glyph="[{ glyph: 'arrows-alt', css: { 'la-stack-1x': true } }, { glyph: 'ban', css: { 'la-stack-2x': true } }]" />
                <open-icon v-else glyph="arrows-alt" />
            </default-button>
        </h6>
        <gallery
            :type="type"
            :images="images"
            :mode="gallery_mode"
            owner="listing"
            :owner-id="listing.id"
            :max-features="(overridable && (type === 'TYPE_IMAGE') && !featuredOnly) ? 2 : 0"
            :copiable="copiable"
            :features="(type === 'TYPE_IMAGE') && featuredOnly"
            @sort="sort_images"
            @held="hold_image"
            @copy="$emit('copy', $event)"
        />
    </div>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import handles_documents from "@/custom/mixins/HandlesDocuments"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ImagesInput",
    mixins: [handles_saving, handles_documents],
    components: {
        DefaultButton,
        OpenIcon,
        Gallery: () => import("@/custom/components/Gallery")
    },
    methods: {
        sort_images (image_ids) {
            return this.save_data_for_record_id("listing", this.listing.id, {
                image_sort: image_ids,
                relation_ids: ["attachment"]
            })
        },
        hold_image (image, name, id, file) {
            this.hold_attachment(this.constants("attachment_types", this.type).id, image.src, name, id, file)
        }
    },
    computed: {
        images () {
            if(!this.type || !this.listing) return []
            const images = this.owner_attachments_of_types("App\\Listing", this.listing.id, [this.constants("attachment_types", this.type).id])
            if(this.featuredOnly) return images.filter(image => !!image.is_featured)
            return images
        },
        gallery_mode () {
            if(this.overridable) return this.sorting ? "sort" : "edit"
            return "view"
        }
    },
    props: {
        type: {
            type: String,
            required: true
        },
        listing: {
            type: Object,
            required: true
        },
        overridable: {
            type: Boolean,
            default: false
        },
        featuredOnly: {
            type: Boolean,
            default: false
        },
        copiable: {
            default: false
        }
    },
    data () {
        return {
            sorting: false
        }
    }
}
</script>
