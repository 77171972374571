<template>
    <section
        :id="id"
        class="nibnut-scroll-target"
    >
        <slot name="title">
            <div v-if="title" class="card-header">
                <div class="card-title h5">
                    {{ title }}
                </div>
            </div>
        </slot>
        <div class="card-body">
            <slot></slot>
        </div>
    </section>
</template>

<script>
export default {
    name: "ListingPanel",
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String
        }
    }
}
</script>
