<template>
    <div
        v-if="role_is_at_least_realtor_for_listing(record) || (is_at_least_seller_for_listing(record) && !!listing_activities.length)"
        class="card card-overflow-visible realtor-activity"
    >
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Recent Activity") }}
            </div>
        </div>
        <div
            class="card-body"
        >
            <timeline
                v-if="!!record"
                :events="listing_activities(record.id)"
                :listing="record"
                :editable="is_at_least_realtor_for_listing(record)"
            />
        </div>
    </div>
</template>

<script>
import events_data_source from "@/custom/mixins/EventsDataSource"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

export default {
    name: "Activity",
    mixins: [events_data_source, addl_profile_utilities],
    props: {
        record: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.realtor-activity {
    .card-body {
        & > .realtor-log {
            table {
                width: 40vw;
                margin: 0 auto;
                td {
                    white-space: nowrap;

                    &.main-col {
                        width: 50%;
                    }
                }
                @media (max-width: $size-md) {
                    width: 60vw;
                }
            }
        }
    }
}
</style>
