<template>
    <div class="listing-quick-stats">
        <div class="columns">
            <div class="column col-4 col-md-6 col-sm-12">
                <apexchart
                    :height="160"
                    :options="chart_options('images')"
                    :series="chart_data('images')"
                />
            </div>
            <div class="column col-8 col-md-6 col-sm-12">
                <apexchart
                    :height="160"
                    type="line"
                    :options="chart_options('social')"
                    :series="chart_data('social')"
                />
            </div>
        </div>
        <base-link
            href="#"
            :title="translate('View All Stats for this Listing')"
            class="text-small"
        >
            {{ translate('All Stats') }}
            <open-icon glyph="arrow-right" size="xs" />
        </base-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import BaseLink from "@/nibnut/components/Links/BaseLink.vue"
import OpenIcon from "@/nibnut/components/OpenIcon"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "QuickStats",
    components: {
        BaseLink,
        OpenIcon,
        Apexchart: VueApexCharts
    },
    methods: {
        raw_data (graph_id) {
            const data = {}
            if(graph_id === "images") {
                let total_clicks = 0
                this.images.forEach(image => {
                    data[image.name] = image.clicks
                    total_clicks += image.clicks
                })
                Object.keys(data).forEach(key => {
                    data[key] = (data[key] / total_clicks) * 100
                })
            }
            if(graph_id === "social") {
                data.Facebook = { color: "#4267B2", clicks: { "01-28": 20, "01-29": 18, "01-30": 19, "02-01": 20, "02-02": 21, "02-03": 19 } }
                data.Twitter = { color: "#1DA1F2", clicks: { "01-28": 10, "01-29": 12, "01-30": 14, "02-01": 18, "02-02": 21, "02-03": 25 } }
                data.Google = { color: "#DB4437", clicks: { "01-28": 0, "01-29": 1, "01-30": 1, "02-01": 2, "02-02": 0, "02-03": 1 } }
            }
            return data
        },
        chart_options (graph_id) {
            const raw_data = this.raw_data(graph_id)
            const labels = Object.keys(raw_data)
            if(graph_id === "images") {
                return {
                    chart: {
                        id: `pie-${Math.random()}`,
                        height: 160,
                        type: "pie",
                        fontFamily: "Quicksand, sans-serif",
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    title: {
                        text: this.translate("Image Clicks")
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: (value, { seriesIndex, w }) => {
                            return w.config.labels[seriesIndex]
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: (value) => {
                                return this.nibnut_filter("nibnut.number", [value, "0,0"]) + "%"
                            }
                        }
                    },
                    labels
                }
            }
            if(graph_id === "social") {
                const values = Object.values(raw_data)
                return {
                    colors: values.map(stat => stat.color),
                    chart: {
                        id: `line-${Math.random()}`,
                        height: 160,
                        type: "line",
                        fontFamily: "Quicksand, sans-serif",
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    title: {
                        text: this.translate("Social Clicks")
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        type: "dateTime",
                        tickAmount: 15,
                        categories: Object.keys(values[0].clicks)
                    },
                    yaxis: {
                        forceNiceScale: true,
                        labels: {
                            formatter: (value, index) => {
                                return this.nibnut_filter("nibnut.number", [value, "0,0"])
                            }
                        }
                    }
                }
            }

            return {}
        },
        chart_data (graph_id) {
            const raw_data = this.raw_data(graph_id)
            if(graph_id === "images") return Object.values(raw_data)
            if(graph_id === "social") {
                return Object.keys(raw_data).map(name => {
                    return {
                        name,
                        data: Object.values(raw_data[name].clicks)
                    }
                })
            }
            return []
        }
    },
    computed: {
        ...mapGetters(["entity_record", "entity_records"]),
        images () {
            const type = this.constants("attachment_types", "TYPE_IMAGE").id
            return this.entity_records("attachment").filter(attachment => {
                return (attachment.owner_type === "App\\Listing") && (attachment.owner_id === this.record.id) && (attachment.type === type)
            })
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
.listing-quick-stats {
    & > a {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
</style>
