<template>
    <listing-panel
        :id="id"
        :title="title"
    >
        <div v-if="is_overridable && !is_mls_record" class="columns">
            <div class="column col-12">
                <listing-attribute :shown="true" class="column">
                    <template v-slot:label>{{ translate("Address") }}</template>
                    <listing-address
                        id="record-address"
                        :listing="listing"
                        :all-errors="allErrors"
                    />
                </listing-attribute>
                <listing-attribute :shown="true" class="column">
                    <template v-slot:label>{{ translate("Area") }}</template>
                    <form-multi-select
                        id="neighborhood_id"
                        name="neighborhood_id"
                        :value="listing.neighborhood_id ? [listing.neighborhood_id] : []"
                        :options="neighborhoods"
                        display="chips"
                        :required="true"
                        :editable="is_overridable && !is_mls_record"
                        :max-selection="1"
                        :error="has_error('neighborhood_id')"
                        class="text-left"
                        @input="save_neighborhood"
                    />
                </listing-attribute>
            </div>
        </div>
        <div
            v-else
            class="columns"
        >
            <listing-attribute :shown="attribute_shown('line1')" class="column col-4 col-md-6 col-sm-12">
                <template v-slot:label>{{ translate("Address") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    {{ listing.line1 }}
                </label>
            </listing-attribute>
            <listing-attribute :shown="attribute_shown('city')" class="column col-4 col-md-6 col-sm-12">
                <template v-slot:label>{{ translate("City") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    {{ listing.city }}
                </label>
            </listing-attribute>
            <listing-attribute :shown="attribute_shown('state')" class="column col-4 col-md-6 col-sm-12">
                <template v-slot:label>{{ translate("Province") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    {{ listing.state }}
                </label>
            </listing-attribute>
            <listing-attribute :shown="attribute_shown('neighborhood_id')" class="column col-4 col-md-6 col-sm-12">
                <template v-slot:label>{{ translate("Area") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    {{ area }}
                </label>
            </listing-attribute>
            <listing-attribute :shown="attribute_shown('neighborhood_id')" class="column col-4 col-md-6 col-sm-12">
                <template v-slot:label>{{ translate("County") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    {{ county }}
                </label>
            </listing-attribute>
            <listing-attribute :shown="attribute_shown('zip')" class="column col-4 col-md-6 col-sm-12">
                <template v-slot:label>{{ translate("Postal Code") }}</template>
                <label
                    class="form-label disabled-field"
                >
                    {{ listing.zip }}
                </label>
            </listing-attribute>
        </div>
    </listing-panel>
</template>

<script>
import is_panel from "./IsPanel"

import FormMultiSelect from "@/custom/components/FormMultiSelect"
import ListingAddress from "./ListingAddress"
import ListingAttribute from "./ListingAttribute"

export default {
    name: "ListingCommunity",
    mixins: [is_panel],
    components: {
        FormMultiSelect,
        ListingAddress,
        ListingAttribute
    },
    methods: {
        save_neighborhood (value, field) {
            if(!!value && !!value.length) value = value[0]
            this.save(value || 0, field)
        }
    },
    computed: {
        neighborhoods () {
            return this.vocabulary("VOCABULARY_NEIGHBORHOODS")
        },
        area () {
            if(!this.listing || !this.listing.neighborhood_id) return ""
            const term = this.entity_record("term", this.listing.neighborhood_id)
            return term ? term.name : ""
        },
        county () {
            if(!this.listing || !this.listing.neighborhood_id) return ""
            let term = this.entity_record("term", this.listing.neighborhood_id)
            if(term && term.parent_id) term = this.entity_record("term", term.parent_id)
            return term ? term.name : ""
        }
    }
}
</script>
