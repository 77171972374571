<template>
    <listing-panel
        :id="id"
        :title="title"
    >
        <!--
        <h6>
            {{ translate("Image Gallery") }}
            <default-button
                v-if="is_overridable && (images.length > 1)"
                flavor="link"
                size="sm"
                :title="sorting ? translate('Stop Reordering Images') : translate('Reorder Images')"
                class="ml-2"
                @click="sorting = !sorting"
            >
                <open-icon v-if="sorting" glyph="ban" />
                <open-icon v-else glyph="arrows-alt" />
            </default-button>
        </h6>
        <gallery
            :images="images"
            :mode="gallery_mode"
            owner="listing"
            :owner-id="listing.id"
            :max-features="is_overridable ? 2 : 0"
            @sort="sort_images"
            @held="hold_gallery_image"
        />
        //-->
        <images-input
            type="TYPE_BANNER"
            :listing="listing"
            :overridable="is_overridable"
        >
            <template v-slot:title>{{ translate("Banners") }}</template>
        </images-input>
        <images-input
            type="TYPE_IMAGE"
            :featured-only="true"
            :listing="listing"
            :overridable="is_overridable"
        >
            <template v-slot:title>{{ translate("Parallax") }}</template>
        </images-input>
        <images-input
            type="TYPE_IMAGE"
            :listing="listing"
            :overridable="is_overridable"
            :copiable="translate('Copy this image to the Banners section')"
            @copy="copy_image_to_banners"
            class="mt-4"
        >
            <template v-slot:title>{{ translate("Image Gallery") }}</template>
        </images-input>
        <!--
        <h6 v-if="is_overridable || !!banner_url" class="mt-4">{{ translate("Banner") }}</h6>
        <div v-if="is_overridable || !!banner_url" class="columns">
            <div class="column col-6 col-md-12">
                <div
                    class="nibnut-aspect-ratio-box nibnut-ratio-banner"
                >
                    <div>
                        <image-upload-input
                            :url="banner_url"
                            name="'banner_url"
                            :alt="translate('Upload new banner image')"
                            :accepts="upload_accepts('banner_url')"
                            :uploading="!!uploading['banner_url']"
                            :uploaded="uploaded['banner_url'] || 0"
                            :deletable="true"
                            :disabled="!is_overridable"
                            @upload="upload_file_list('banner_url', $event)"
                            @clear="confirm_clear_file('banner_url')"
                        />
                    </div>
                </div>
            </div>
        </div>
        //-->
        <h6 v-if="show_urls" class="mt-4">{{ translate("Media URLs") }}</h6>
        <div v-if="show_urls" class="columns">
            <div
                v-for="field in MEDIA_URL_FIELDS"
                :key="field"
                class="column col-4 col-md-6 col-sm-12"
            >
                <form-url-input
                    :id="field"
                    :name="field"
                    v-model="listing[field]"
                    :required="false"
                    :error="has_error(field)"
                    @input="save"
                >
                    <template v-slot:label>{{ media_url_label(field) }}</template>
                </form-url-input>
            </div>
        </div>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </listing-panel>
</template>

<script>
import is_attachments_panel from "./IsAttachmentsPanel"

// import ImageUploadInput from "@/nibnut/components/Inputs/ImageUploadInput"
// import Gallery from "@/custom/components/Gallery"
import FormUrlInput from "@/custom/components/FormUrlInput"
import ImagesInput from "./ImagesInput"

export default {
    name: "ListingMedia",
    mixins: [is_attachments_panel],
    components: {
        // ImageUploadInput,
        // Gallery,
        FormUrlInput,
        ImagesInput
    },
    created () {
        this.MEDIA_URL_FIELDS = [
            "brochure_url",
            "photo_url",
            "sound_url",
            "video_url",
            "tour_url",
            "narrated_video_url",
            "uav_footage_url",
            "room_planner_url",
            "thing_url"
        ]
    },
    methods: {
        media_url_label (field) {
            if(field === "brochure_url") return this.$root.translate("Brochure URL")
            if(field === "photo_url") return this.$root.translate("Photo URL")
            if(field === "sound_url") return this.$root.translate("Sound File URL")
            if(field === "video_url") return this.$root.translate("Video URL")
            if(field === "tour_url") return this.$root.translate("Tour URL")
            if(field === "narrated_video_url") return this.$root.translate("Narrated Video URL")
            if(field === "uav_footage_url") return this.$root.translate("UAV Footage URL")
            if(field === "room_planner_url") return this.$root.translate("Room Planner URL")
            if(field === "thing_url") return this.$root.translate("Thing URL")
            return field
        },
        copy_image_to_banners (image) {
            const data = {
                ...image,
                ...{ type: this.constants("attachment_types", "TYPE_BANNER").id, sort_order: 0, is_featured: false }
            }
            console.log(image, data)
            this.save_data_for_record_id("attachment", 0, data)
        }
    },
    computed: {
        show_urls () {
            if(!this.listing) return false
            if(this.is_overridable) return true
            return !!this.MEDIA_URL_FIELDS.find(field => !!this.listing[field])
        }
    },
    data () {
        return {
            sorting: false
        }
    }
}
</script>
