<template>
    <listing-panel
        v-if="has_content"
        :id="id"
        :title="title"
    >
        <div class="columns">
            <listing-attribute
                :shown="features_shown('VOCABULARY_EXTERIOR_FEATURES')"
                :vertical="true"
                :class="{ 'col-12': is_overridable && !is_mls_record }"
                class="column"
            >
                <template v-slot:label>{{ translate("Exterior") }}</template>
                <listing-features
                    type="VOCABULARY_EXTERIOR_FEATURES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                    :class="{ 'by-3': !!$mq.match(/^(lg|tk|xl|2x|xxl)$/i), 'by-1': !$mq.match(/^(lg|tk|xl|2x|xxl)$/i) }"
                />
            </listing-attribute>
            <listing-attribute
                :shown="features_shown('VOCABULARY_LOT_FEATURES')"
                :vertical="true"
                class="column"
            >
                <template v-slot:label>{{ translate("Lot") }}</template>
                <listing-features
                    type="VOCABULARY_LOT_FEATURES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                    :class="{ 'by-3': !!$mq.match(/^(lg|tk|xl|2x|xxl)$/i), 'by-1': !$mq.match(/^(lg|tk|xl|2x|xxl)$/i) }"
                />
            </listing-attribute>
            <listing-attribute
                :shown="features_shown('VOCABULARY_FENCE_TYPES')"
                :vertical="true"
                class="column"
            >
                <template v-slot:label>{{ translate("Fence(s)") }}</template>
                <listing-features
                    type="VOCABULARY_FENCE_TYPES"
                    :term-ids="listing.term_ids"
                    :editable="is_overridable && !is_mls_record"
                    :all-errors="allErrors"
                    :class="{ 'by-3': !!$mq.match(/^(lg|tk|xl|2x|xxl)$/i), 'by-1': !$mq.match(/^(lg|tk|xl|2x|xxl)$/i) }"
                />
            </listing-attribute>
        </div>
    </listing-panel>
</template>

<script>
import is_panel from "./IsPanel"

import ListingFeatures from "./ListingFeatures"
import ListingAttribute from "./ListingAttribute"

export default {
    name: "ListingExterior",
    mixins: [is_panel],
    components: {
        ListingFeatures,
        ListingAttribute
    },
    computed: {
        has_content () {
            return !!this.listing && (
                !this.listing.id ||
                this.features_shown("VOCABULARY_EXTERIOR_FEATURES") ||
                this.features_shown("VOCABULARY_LOT_FEATURES") ||
                this.features_shown("VOCABULARY_FENCE_TYPES")
            )
        }
    }
}
</script>
