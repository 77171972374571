var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.has_content)?_c('listing-panel',{attrs:{"id":_vm.id,"title":""}},[_c('h5',[_vm._v(" "+_vm._s(_vm.translate("Rooms"))+" "),_c('span',{staticClass:"ml-8"},[_c('open-icon',{attrs:{"glyph":"bath"}}),_vm._v(" "+_vm._s(_vm._f("nibnut.number")(_vm.bathrooms.length,"0,0"))+" ")],1),_c('span',{staticClass:"ml-8"},[_c('open-icon',{attrs:{"glyph":"bed"}}),_vm._v(" "+_vm._s(_vm._f("nibnut.number")(_vm.bedrooms.length,"0,0"))+" ")],1)]),_c('data-table',{attrs:{"id":"listing-rooms","columns":_vm.visible_columns,"rows":_vm.filtered_rows,"total":_vm.state.total,"found":_vm.state.found,"current-page":_vm.state.page,"total-pages":_vm.nb_pages,"can-add":_vm.is_overridable && !_vm.is_mls_record,"searchable":false,"entity-name":"room:::rooms"},on:{"add":_vm.add_record,"sort":_vm.sort_by,"page":_vm.goto_page},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var row = ref.row;
return [(_vm.row_option_for_field(row, 'type_id'))?_c('h6',[_vm._v(" "+_vm._s(_vm.row_option_for_field(row, 'type_id').name)+" ")]):_vm._e(),(_vm.row_option_for_field(row, 'level_id'))?_c('h6',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.row_option_for_field(row, 'level_id').name)+" ")]):_vm._e(),_c('form-input',{attrs:{"id":("dimensions-" + (row.id)),"name":"dimensions","required":false,"editable":_vm.is_overridable && !_vm.is_mls_record,"error":_vm.has_error('dimensions')},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.save_room.apply(void 0, [ row ].concat( argsArray ))}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.translate("Dimensions")))]},proxy:true}],null,true),model:{value:(row.dimensions),callback:function ($$v) {_vm.$set(row, "dimensions", $$v)},expression:"row.dimensions"}}),(_vm.is_overridable && !_vm.is_mls_record)?_c('default-button',{attrs:{"color":"error","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.confirm_room_delete(row)}}},[_c('open-icon',{attrs:{"glyph":"minus-circle"}}),_vm._v(" "+_vm._s(_vm.translate("Delete"))+" ")],1):_vm._e()]}},{key:"tbody",fn:function(ref){
var row = ref.row;
var field = ref.field;
return [((field === 'type_id') || (field === 'level_id'))?_c('form-dropdown',{attrs:{"id":field,"name":field,"options":_vm.options_for_field(field),"required":true,"editable":_vm.is_overridable && !_vm.is_mls_record,"error":_vm.has_error(field)},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.save_room.apply(void 0, [ row ].concat( argsArray ))}},model:{value:(row[field]),callback:function ($$v) {_vm.$set(row, field, $$v)},expression:"row[field]"}}):_c('div',{staticClass:"d-flex"},[_c('form-input',{staticClass:"flex-grow mb-0",attrs:{"id":(field + "-" + (row.id)),"name":field,"required":false,"editable":_vm.is_overridable && ((field === 'dimensions') || !_vm.is_mls_record),"error":_vm.has_error(field)},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.save_room.apply(void 0, [ row ].concat( argsArray ))}},model:{value:(row[field]),callback:function ($$v) {_vm.$set(row, field, $$v)},expression:"row[field]"}}),(_vm.is_overridable && !_vm.is_mls_record && (field === 'dimensions'))?_c('default-button',{staticClass:"flex-static ml-2",attrs:{"flavor":"link","color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.confirm_room_delete(row)}}},[_c('open-icon',{attrs:{"glyph":"minus-circle"}})],1):_vm._e()],1)]}}],null,false,3536738263)}),(!!_vm.confirming)?_c('confirmation',_vm._b({on:{"cancel":_vm.done_confirming,"confirmed":_vm.confirmed}},'confirmation',_vm.confirmation_props,false)):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }